@import (reference) "../../../common/styles/aria-web";

@page-background-color: @gray1;
@box-width: 600px;

.unauthorized {
    background-color: @page-background-color;
    width: @box-width;

    p {
        margin: 0;

        &.header {
            color: @blue;
            font-size: 37.5px;
        }

        &.text {
            font-size: 18.5px;
            line-height: 29px;
            margin-bottom: 20px;
        }
    }

    .image {
        margin-bottom: 30px;
        color: #0072CC;
        width: 300px;
        height: 300px;
    }

    .logout {
        cursor: pointer;
    }
}

@enable-css-reset: false;