@import (reference) "../../common/styles/aria-web";
@avatar-size: 50px;
@top-padding: @xl;

.home-box-style-mixin {
    box-shadow: 0 2px 32px var(--dark-theme-box-shadow-color, rgba(29,59,81,.16));
}

home {
    flex: 1;
    background: @gray2;

    .home {
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .purple {
            background: @purple;
        }

        .orange {
            background: @green;
        }

        .blue {
            background: @blue;
        }

        .green {
            background: @green;
        }

        .home-box-style {
            .home-box-style-mixin;
        }

        aui-spinner {
            height: 125px;
        }

        .center-inner {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
        }

        app-titlebar {
            width: 100%;
        }

        .feed-short {
            display: none;
        }

        home-content {
            position: relative;
            display: flex;
            padding: @top-padding @lg;
            overflow: auto;

            .body {
                box-sizing: content-box;
                display: flex;
                margin: auto;

                .pinned-card-header {
                    height: @lg + @md;
                    margin: @stack-md;

                    &.flexible {
                        height: auto;
                    }

                    .pinned-icon {
                        color: @blue;
                        float: right;
                    }

                    .post-user {
                        display: inline;
                        margin: 0;
                    }

                    .avatar {
                        .center-inner;
                        border-radius: 50%;
                        float: left;
                        margin-right: @sm + @xs;
                        margin-top: -5px;
                    }

                    .avatar-aria {
                        .center-inner;
                        border-radius: 50%;
                        float: left;
                        margin-right: 15px;
                        margin-top: -5px;
                        width: @avatar-size;
                        height: @avatar-size;

                        i {
                            color: @white;
                            font-size: 30px;
                        }
                    }
                }

                .message {
                    .message-title {
                        .text-epsilon;
                        color: @gray6;
                    }

                    p {
                        .text-body;
                        color: @gray5;
                        white-space: pre-wrap;
                    }

                    .arrow-style {
                        margin-left: 5px;
                    }
                }

                .timestamp {
                    font-size: 11px;
                    color: @gray5;
                    display: block;
                }
            }
        }
    }
}

@enable-css-reset: false;