@import (reference) "../../../../common/styles/aria-web";
@import (once) "../../../dashboard-app/visualization-zoom/visualization-zoom.less";

@viz-spacing: 15px;
@viz-height: 385px;

.health-author {
    flex: 1;
    position: relative;
    background: @gray2;
    display: flex;
    height: 100%;

    .health-visualizations {
        &.sidebar-open {
            width: ~"calc(100vw - @{standard-dim-width} - @{document-editor-width})";
        }

        .scroll-padding-bottom;
        width: ~"calc(100vw - @{standard-dim-width})";
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: @viz-spacing;
        padding-right: @viz-spacing;

        .filter-container {
            display: flex;
            margin-top: @md;

            aui-text-field, aui-dropdown-selector {
                flex: 1;
            }

            aui-dropdown-selector {
                // TODO create class for this style of dropdown used around the portal
                height: 40px;
                border-bottom: 1px solid @gray3;
            }
        }
    }

    .health-vis-wrapper {
        margin-top: @viz-spacing;
        min-height: @viz-height;
        height: fit-content;
    }
}

.author-zoom-body {
    display: flex;
    flex-direction: column;
    flex: 1;
}

@enable-css-reset: false;