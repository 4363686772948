@import (reference) "../../components/app-skeleton/app-skeleton.less";
@import (once) "../new-user-tour/input-form/input-form.less";

user-role-members,
user-role-subscriptions {
    height: ~"calc(100vh - @{app-with-tabs-spacing})";
    display: flex;
    flex-direction: column;
}

.subscription-table.table {
    thead tr th.header {
        &:first-child {
            padding-left: 30px;
        }

        padding: 8px 15px;
    }

    .subscription-row {
        td {
            padding: 0;

            i {
                &.no-subscription {
                    color: @red;
                }

                &.icon-toggle-on, &.icon-toggle-off {
                    padding-left: 15px;
                }
            }

            &:first-child {
                padding-left: 19px;
            }

            .tag-list {
                margin-left: 10px;
            }
        }
    }

}

user-role-app {
    flex: 1;
}

.user-role-manager-subscribers-tables,
.user-role-manager-members-tables {
    display: flex;
    width: 100%;
    height: 100%;
}

user-role-contact {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 20px;
        max-width: 580px;

        form {
            display: flex;
            flex-direction: column;
        }

        h2 {
            .text-beta;
        }

        label {
            text-transform: uppercase;
            font-weight: bold;
            margin-left: 10px;
            padding-top: 15px;
            padding-bottom: 4px;

            i {
                font-size: 16px;
                color: gray;
                margin-left: 5px;
                position: relative;
                top: -1px;
            }
        }

        p,
        h2 {
            margin-left: 10px;
        }

        textarea {
            background-color: @gray1;
            border: none;
            padding: 5px;
            padding-left: 10px;
            width: 100%;
            resize: vertical;

            &.ng-invalid {
                border: 1px solid @red;
            }
        }

        aui-text-field {
            width: 100%;
        }

        fluent-text-field {
            width: 100%;
        }

        fluent-dropdown-field {
            width: 300px;
            height: 40px;
        }

        .aui-dropdown-selector {
            text-transform: uppercase;
            width: 300px;
            height: 40px;

            .aui-dropdown {
                text-transform: uppercase;
            }
        }
    }
}

@enable-css-reset: false;