@import (reference) "../kusto-manager.less";

.app-skeleton.kusto-database {
    ui-view {
        .flex-layout-column;
        overflow: hidden;

        kusto-manager-database {
            .flex-layout-column;
            overflow: hidden;

            .kusto-manager-database {
                .flex-layout-column;
                overflow: hidden;

                .database-table {
                    flex-grow: 1;
                    position: relative;
                    .scroll-padding-bottom;

                    h3 {
                        margin: 0;
                        display: none;

                        &.header-results {
                            position: absolute;
                            top: @app-skeleton-filtered-table-header-row-gap + (3 * @app-skeleton-filtered-table-filter-row-gap);
                            left: 0;
                        }
                    }

                    .filter-list {
                        .aui-text-field {
                            padding-left: @app-skeleton-table-left-padding;
                        }
                    }

                    table {
                        table-layout: fixed;
                        min-width: 800px;

                        tr {
                            td {
                                &.table-name {
                                    > div {
                                        // Make sure that both table name and
                                        // warning icon are horizontally
                                        // aligned
                                        height: 100%;
                                        display: flex;
                                        align-items: center;

                                        a {
                                            .text-overflow;
                                        }

                                        i {
                                            padding-left: 5px;
                                        }
                                    }
                                }
                            }

                            // Right aligning right most columns
                            th {
                                &.table-storage-quota {
                                    text-align: right;
                                }
                            }

                            td {
                                &.table-storage-quota {
                                    input {
                                        text-align: right;
                                    }
                                }
                            }

                            td, th {
                                &.table-retention-estimate,
                                &.table-estimated-storage,
                                &.table-peak-ingestion,
                                &.table-space-used,
                                &.table-percent-space-used {
                                    text-align: right;
                                }
                            }

                            // The list of rules below defines widths of columns.
                            // For clearness, do not extend those selectors with
                            // any other properties, but rather apply new rules
                            // somewhere else in this file
                            th {
                                &:first-child {
                                    width: 30%;
                                }

                                &:nth-child(2) {
                                    width: 8em;
                                }

                                &.table-retention-time {
                                    width: 12em;
                                }
                            }
                        }
                    }
                }

                .total-allocation {
                    .aria-semi-bold;
                    background-color: @gray1;
                    padding: 0 30px;
                    border-top: 1px solid @gray2;
                    font-size: 11px;
                    color: @gray6;
                    font-weight: normal;

                    .allocation-info {
                        margin: 10px 0;
                        display: flex;
                        justify-content: space-between;

                        .total-used, .total-allocated {
                            font-family: @aria-font-light;
                        }
                    }

                    .total {
                        padding-right: 15px;
                    }

                    .progress {
                        background-color: @white;
                        border-radius: 10px;
                        border: 1px solid @gray2;
                        height: 10px;

                        .progress-bar {
                            &.bg-success {
                                background-color: @green;
                            }

                            &.bg-warning {
                                background-color: @yellow;
                            }

                            &.bg-danger {
                                background-color: @red;
                            }
                        }
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;