@import (reference) "../../../../common/styles/aria-web";

project-list-viewer {
    height: 100vh;

    .taskpane-header-label {
        margin-left: 44px;
    }

    .scroll-wrapper {
        position: relative;
    }

    .aui-list {
        .scroll-padding-bottom;
        width: 100%;
    }
}

@enable-css-reset: false;