@import (reference) "../../common/styles/aria-web";
@group-selector-button-height: 44px;
@group-selector-dropdown-button-width: 34px;


group-selector {
    .aria-regular;
    background: transparent;
    display: flex;
    height: @group-selector-button-height;

    &:hover {
        cursor: pointer;
    }

    .group-selector {
        .group-selector-toggle {
            border: 0;
            overflow: hidden;
            height: 100%;
            width: 100%;
            background: transparent;
            display: flex;
            align-items: center;

            aui-spinner {
                display: inherit;
            }

            &:hover, &:active {
                .darken10;
            }

            .group-selector-toggle-content {
                .text-overflow;
                align-items: center;
                text-align: left;
                padding: 0 10px;

                span {
                    float: left;
                }

                .icon-arrow-down {
                    margin-left: 10px;
                    float: right;
                    padding-top: 4px;
                }
            }
        }
    }

    aui-tree.group-selector-tree {
        background: @gray6;
        max-width: 400px;
        border-radius: 0;
        margin: 0;

        ol {
            padding-left: @group-selector-dropdown-button-width;
        }

        .tree-container {
            padding-left: 0;

            .tree-node {
                display: flex;
                border: none;
                height: 35px;
                color: @white;
                background: transparent;

                // Ensure that the tree nodes are hoverable and clickable over
                // the full width of the tree control
                margin: 0 0 0 -100vw;
                padding: 0 0 0 100vw;

                .group-name-container {
                    .text-overflow;
                    padding: 5px 25px 5px 5px;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }

                .btn-dropdown {
                    width: @group-selector-dropdown-button-width;
                    height: 35px;
                    display: inline-block;
                    text-align: center;
                    background: transparent;
                    padding-top: 5px;
                    vertical-align: top;

                    &:hover {
                        background: @gray5;
                    }
                }

                &:hover {
                    background: @gray6;
                }
            }
        }
    }
}

@enable-css-reset: false;