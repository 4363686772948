@import (reference) "../../common/styles/aria-web";

@titlebar-height: 44px;
@max-item-width: 250px;

.app-titlebar {
    color: @white;
    background-color: @blue;
    align-items: center;
    flex: 0 1 @titlebar-height;
    display: flex;
    height: @titlebar-height;
    min-height: @titlebar-height;
    padding: 0 @toolbar-right-padding 0 @toolbar-left-padding;

    button:not([class^=fui-]) {
        max-width: @max-item-width;
    }

    span:not([class^=fui-]) {
        .text-overflow;
        max-width: @max-item-width;
    }

    .fui-Button {
        // Fix for flickering buttons on Popover
        transition: none;
    }

    aui-text-button {
        height: 100%;
        .aria-regular;
    }

    aui-dropdown-selector {
        .aui-dropdown-content {
            .aria-regular;
        }
    }

    span.app-id {
        padding: 0px 15px;
        .aria-regular;
    }

    .resource-name {
        padding-left: @toolbar-left-padding;
    }

    .aui-breadcrumb {
        .icon-breadcrumb-pipe, .icon-breadcrumb-arrow {
            color: @blue-darken25;
            // Mocks sets 20px for the pipe which seems to work with 30px in
            // the glyph (the glyph have some paddings on top and bottom)
            font-size: 30px;
            margin: 0 -10px;
            cursor: default;
        }

        project-selector i {
            color: @white;
        }

        .breadcrumb-text {
            padding-left: 16px;
        }

        display: flex;
        align-items: center;
    }

    .titlebar-content {
        height: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
    }

    .app-titlebar-group-selector {
        width: 500px;
        max-height: 60vh;
    }

    search-input {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

@enable-css-reset: false;