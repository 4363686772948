@import (reference) "../../rta-cube-manager.less";

@rta-cube-manager-editor-pad-padding:               @app-skeleton-editor-pad-padding;
@rta-cube-manager-editor-pad-plain-text-padding:    @app-skeleton-editor-pad-plain-text-padding;

@rta-cube-manager-editor-capping-configuration-modal-height: 80vh;

rta-cube-manager-editor {
    .flex-layout-column;
    overflow: hidden;

    .rta-cube-manager-editor {
        ng-form.editor-form {
            fieldset {
                .step-no-1 {
                    margin-top: 18px;
                }

                .step-contents {
                    .table {
                        tbody {
                            td {
                                &.project {
                                    padding: 0;

                                    button {
                                        padding: 9px;
                                    }
                                }

                                &.event-selector {
                                    .dropdown-menu {
                                        a {
                                            padding-left: @rta-cube-manager-editor-pad-padding;
                                        }
                                    }
                                }

                                &.property-name, &.function-name, &.function-description {
                                    .text-overflow-table-cell;
                                }

                                &.derived-property-name {
                                    padding-left: 0;

                                    input {
                                        padding-left: 8px;
                                    }
                                }

                                &.aggregation {
                                    > span {
                                        display: block;
                                        width: 100%;
                                        .text-overflow;
                                    }
                                }
                            }
                        }

                        // The list of rules below defines widths of columns.
                        // For clearness, do not extend those selectors with
                        // any other properties, but rather apply new rules
                        // somewhere else in this file
                        &.table-events {
                            th:first-child {
                                width: 30%;
                            }
                        }

                        &.table-derived-properties {
                            th:first-child {
                                width: 30%;
                            }

                            th:nth-child(2) {
                                width: 30%;
                            }
                        }

                        &.table-property-filters {
                            th:first-child {
                                width: 30%;
                            }

                            th:nth-child(2) {
                                width: 30%;
                            }
                        }

                        &.table-measures {
                            th:first-child {
                                width: 15%;
                            }

                            th:nth-child(2), td:nth-child(2) {
                                width: 15%;
                            }

                            th:nth-child(3) {
                                width: 15%;
                            }

                            th:nth-child(4) {
                                width: 15%;
                            }
                        }

                        &.table-dimensions {
                            th:first-child {
                                width: 30%;
                            }

                            th:nth-child(2) {
                                width: 30%;
                            }
                        }
                    }
                }
            }

            .bottom-bar {
                .restart {
                    padding-left: 15px;
                    color: @white;

                    button {
                        background-color: @green;
                    }
                }
            }
        }
    }
}

// Overridding setting from the app skeleton, as highest dropdown here is not
// present at the bottom of the page. Need to explicitly use long list of
// selectors to make sure that this one is more accurate than the one defined
// in the app skeleton's less file
.app-skeleton rta-cube-manager-editor .rta-cube-manager-editor ng-form.editor-form .scroll-container fieldset .spacer {
    padding-bottom: 150px;
}

.rta-cube-manager-editor-capping-configuration-modal {
    .modal-dialog {
        max-height: @rta-cube-manager-editor-capping-configuration-modal-height;

        .modal-content {
            max-height: @rta-cube-manager-editor-capping-configuration-modal-height;
        }

        aui-modal-body {
            position: relative;
        }
    }
}

@enable-css-reset: false;