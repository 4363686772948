@import (reference) "../../common/styles/aria-web";

.feedback-modal {
    label {
        margin-left: @sm;
    }

    .feedback-textarea {
        margin-top: @sm;
        background: @gray1;
        border: none;
        border-bottom: 2px solid @gray3;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        padding-left: @sm;

        &:focus {
            outline: none;
            border-bottom: 2px solid @blue;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
        }
    }

    .commit {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    aui-spinner {
        padding-left: 5px;
    }

    .feedback-textarea {
        height: 184px;
    }
}

@enable-css-reset: false;