@import (reference) "../../../../common/styles/aria-web";

@app-skeleton-editor-step-no-circumference: 25px;

.app-skeleton-editor-step {
    margin-left: 10px;
    position: relative;

    .step-header {
        display: flex;

        .step-header-hint {
            line-height: @app-skeleton-editor-step-no-circumference;
            margin-left: 8px;
            font-size: 18px;
            margin-top: 2px;
            color: @gray5;
        }

        .step-no {
            width: @app-skeleton-editor-step-no-circumference;
            height: @app-skeleton-editor-step-no-circumference;
            background-color: @gray2;
            border-radius: 50%;
            color: @white;
            text-align: center;
            line-height: @app-skeleton-editor-step-no-circumference;
            font-size: 12px;
        }

        > h2 {
            .text-gamma;
            margin: 0 0 0 35px;
            line-height: @app-skeleton-editor-step-no-circumference;
            color: @gray5;
        }
    }

    .step-container {
        border-left: 1px solid @gray2;
        margin: 8px 0 8px 12px;
        padding: 12px 0 12px 40px;

        .step-contents {
            display: none;
            padding: 0 0 15px;
        }

        .buttons {
            display: none;
            padding: 15px 0 0;
            .aria-regular;

            .next {
                color: @gray5;
                margin-right: 10px;

                button {
                    background-color: @gray2;
                }
            }

            .reset {
                color: @gray6;

                button {
                    background-color: @gray2;
                }

                &:hover {
                    color: @gray7;
                }
            }
        }
    }

    &.active {
        .step-header {
            .step-no {
                .aria-semi-bold;
                background-color: @blue;
            }

            > h2 {
                color: @blue;
            }
        }

        .step-contents {
            display: block;
        }

        .buttons {
            display: block;
        }
    }

    &.valid {
        .buttons {
            .next {
                color: @white;

                button {
                    background-color: @blue;
                }
            }
        }
    }

    &.completed {
        .step-header {
            .step-no {
                background-color: @blue;
            }

            > h2 {
                color: @blue;
            }
        }

        .step-contents {
            display: block;
        }
    }

    &.final {
        .step-container {
            border-left: none;
        }
    }

    &.undecorated {
        // Remove left margin on the step, so that the contents can be aligned
        // with other steps and application name
        margin: 0;

        // Hide the header
        .step-header {
            display: none;
        }

        // Remove all decoration from contents container
        .step-container {
            border: none;
            margin: 0;
            padding: 0;
        }
    }
}

@enable-css-reset: false;