@import (reference) "../../../../common/styles/aria-web";

.role-membership-gauge {
    display: flex;

    span {
        cursor: pointer;
        font-size: 28px;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: solid 1px @gray5;
        margin: 5px 2px 5px;
        background-color: @white;

        &.turned-on {
            background-color: @blue;
            border-color: @blue;
        }

        &.highlighted {
            background-color: @green;
            border-color: @green;
        }
    }

    &.read-only {
        span {
            border-color: @gray5;

            &.highlighted {
                background-color: @white;
            }

            &.turned-on {
                background-color: @gray5;
            }
        }
    }
}

@enable-css-reset: false;