@import (reference) "../../common/styles/aria-web";
@import "../../../../node_modules/@ms-aria/app-fx/src/ui/editors/controls/selectors-base";

.aui-subscription-modal {
    height: 600px;

    .subscription-form {
        height: 100%;

        .loading {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                .text-delta;
                padding-top: @lg;
                color: @gray5;
                text-align: center;
            }

            aui-spinner {
                height: auto;

                circle {
                    stroke: @blue;
                }
            }
        }
    }

    aui-modal-body {
        label {
            padding-left: @sm;
            margin-bottom: 0;
            padding-top: 15px;
        }

        textarea {
            background-color: @gray1;
            border: none;
            padding: 5px;
            padding-left: 10px;
            width: 100%;
            resize: vertical;

            &.ng-invalid {
                border: 1px solid @red;
            }
        }

        .aui-dropdown {
            width: 100%;
            border-bottom: 1px solid @gray3;
            padding: 0 10px;
        }

        .aui-dropdown-selector
        {
            margin-bottom: @md+@sm;

            .aui-dropdown-menu {
                width: 100%;

                .aui-dropdown-list {
                    max-width: 100%;
                    max-height: 40vh;
                }
            }
        }

        .aui-row-button {
            .row-wrapper {
                .label-text {
                    padding-left: @sm;
                }
            }
        }
    }

    .aui-modal-footer {
        aui-text-button {
            .clone-text {
                width: 100%;
            }
        }
    }
}

@enable-css-reset: false;