@import (reference) "../../common/styles/aria-web";

event-explorer-app {
    height: 100vh;
    flex: 1;

    table.dataTable thead {
        text-transform: uppercase;
        font-size: 12px;
        border-top: 1px solid @gray2;
    }

    table.dataTable tr {
        height: 44px;
    }

    .app-skeleton aui-dropdown-selector .dropdown-menu {
        width: initial;
    }

    .property-editor p {
        padding: 0px 49px;
    }

    .inspector-controls {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .event-count-display {
            .aria-regular;
            display: flex;
            align-items: center;
            margin: 0 10px;
            .text-overflow;
        }

        input[type="text"] {
            .darken25;
            .aria-regular;
            padding: 0 10px;
            border: 0;
            border-radius: 3px;
            height: 30px;
            margin: 0 7px;
            width: 192px;
        }

        input {

            // cannot group these placeholders or they will be invalid
            &::placeholder { color: @gray3; }

            &::-webkit-input-placeholder { color: @gray3; }

            &::-moz-placeholder { color: @gray3; }

            &::-moz-placeholder { color: @gray3; }

            &:-ms-input-placeholder { color: @gray3; }
        }

        .listening {
            cursor: default;
            animation: fade-out-in 2.5s infinite;

            @keyframes fade-out-in {
                0%   { opacity : 1; }

                50%  { opacity : 0; }

                100% { opacity : 1; }
            }
        }
    }

    // fix for firefox for the play/pause button
    .aui-icon-button > i {
        display: block;
    }

    .ee-title {
        padding: 20px 30px 20px 20px;
        width: 70%;

        .event-name {
            .aria-semi-light;
            font-size: 28px;
            padding-left: 10px;
        }

        .event-description {
            height: 40px;
            color: @gray6;

            &.static-text {
                display: flex;
                align-items: center;
                padding-left: 10px;
            }
        }

        .icon-warning {
            color: red;
            cursor: default;
            width: 24px;
        }
    }

    .tab-bar {
        display: flex;

        aui-tab-header {
            flex: 1;
        }

        .all-events-link {
            display: flex;
            flex-grow: 0;
            border-bottom: 1px solid @gray2;

            a {
                .aria-regular;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 20px;
                flex: 1;
            }
        }
    }
}

.aui-column-chooser-modal {
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    aui-modal-body {
        padding: 0;
        height: 80vh;
    }

    .aui-modal-body {
        padding: 0;
        margin: 0;

        .aui-select {
            .aui-select-heading {
                background-color: @gray1;
                border: 0;
                padding: 0 15px;

                &.focused {
                    border: 0;
                }
            }

            .aui-select-content {
                height: 60vh;
            }

            box-shadow: none;
        }

        .aui-list-item {
            padding: 0 15px;
        }
    }
}

.aui-event-properties-modal .aui-visualization-base .dataTables_wrapper table.dataTable tr:hover {
    cursor: default;
}

@enable-css-reset: false;