@import (reference) "../../../components/app-skeleton/app-skeleton-mixins.less";

.dashboard-app-choose-visualisation-metric-modal {
    .controls {
        aui-dropdown-selector {
            .app-skeleton-dropdown-full-width-mixin;
        }

        .aui-dropdown-selector {
            margin: 0 @sm;

            .aui-dropdown {
                border-bottom: 1px solid @gray3;
            }
        }
    }
}

@enable-css-reset: false;