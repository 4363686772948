@import (reference) "../../rta-cube-manager/settings/settings.less";

.app-skeleton.update-cube {
    ui-view {
        kusto-cube-manager-settings {
            .flex-layout-column;
            overflow: hidden;

            .kusto-cube-manager-settings {
                .flex-layout-column;
                overflow: hidden;

                &.legacy {
                    .kusto-cube-manager-editor {
                        fieldset {
                            padding-top: 0;
                        }

                        app-skeleton-editor-step:first-child {
                            .app-skeleton-editor-step {
                                margin-top: 0;
                            }
                        }
                    }

                    .resource-status-messages {
                        li {
                            padding-bottom: 0;

                            label {
                                margin-bottom: 0;
                                padding-bottom: 0;
                            }

                            a {
                                padding-left: 20px;
                                font-size: small;
                            }
                        }
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;