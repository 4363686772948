@import (reference) "../../rta-cube-manager.less";

.rta-cube-manager-editor-property-selector {
    position: relative;

    aui-text-field {
        .dropdown-menu {
            .app-skeleton-typeahead-dropdown-mixin;
        }
    }

    aui-text-field.additional-info span.optional {
        display: none;
    }

    &.input-table-cell {
        input {
            .text-field-as-table-cell;
        }
    }
}

@enable-css-reset: false;