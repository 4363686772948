@import (reference) "../../common/styles/aria-web";

@app-skeleton-dropdown-max-height: 300px;

.app-skeleton-typeahead-dropdown-mixin {
    width: 100%;
    border-radius: 0;
    border: none;
    background-color: @white;
    max-height: @app-skeleton-dropdown-max-height;
    cursor: pointer;

    li {
        a {
            color: @fluent-gray180;
            display: flex;
            padding: 8px 0 8px 10px;
            font-size: 13.5px;

            span {
                .text-overflow;

                &:first-child {
                    flex-basis: 50%;
                }
            }

            &.one-column {
                span {
                    flex-basis: auto;
                    padding-right: 10px;
                }
            }
        }

        &.active, &:hover {
            a {
                background-color: @fluent-gray20;
                color: @fluent-gray180;
            }
        }
    }
}

.app-skeleton-dropdown-full-width-mixin {
    .dropdown-toggle {
        width: 100%;

        .aui-dropdown-content {
            justify-content: space-between;
        }

        .aui-dropdown-text {
            // Override the max width (set to 270px in the Aria.js), so that
            // the text takes full width of the extended dropdown button
            max-width: 100%;
        }
    }

    .dropdown-menu {
        width: 100%;

        .aui-dropdown-list {
            max-width: 100%;

            // Override the max width (set to 270px in the Aria.js), so
            // that the text takes full width of the extended dropdown
            // entry
            a {
                span {
                    max-width: 100%;
                }
            }
        }
    }
}
