@import (reference) "./rta-cube-manager-editor.less";

.rta-cube-manager-editor-tab-controls {
    display: flex;

    .restart, .overrides {
        margin-right: 20px;
    }
}


@enable-css-reset: false;