@import (reference) "../project-manager.less";

.project-manager {
    .settings {
        padding: @app-skeleton-padding-when-no-table;

        fieldset {
            padding-left: @app-skeleton-padding-when-no-dashboard - 10px; // We need to account for paddings in inputs
        }

        .switch-info {
            margin-top: @sm;
            padding: @sm;
            background: lightcyan;
            border-radius: @sm;
            display: flex;
            gap: @xs;
            align-items: center;
            width: fit-content;
        }
    }

    .label-header {
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 10px;
        padding-top: 15px;
        padding-bottom: 4px;
    }
}


.checkbox-slider-container {
    margin-left: 10px;
    position: relative;
    cursor: pointer;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark:after {
        content: "\EA7B";
    }

    input:checked ~ .checkmark:after {
        content: "\EA7C";
    }

    input:disabled ~ .checkmark:after {
        color: #ccc;
    }
}

@enable-css-reset: false;