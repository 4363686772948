@import (reference) "../../app-skeleton.less";

// Change the look-and-feel of the first step in case of resource edition. In
// case of resource creation we use default styles from the skeleton. But in
// case of edition we switch from tabular view to simple stacked view
.app-skeleton {
    .resource-manager-editor {
        &.action-edit {
            .app-skeleton-common-properties {
                .table {
                    // Hide the first row with labels
                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            // Switch the second row to the stacked view
                            display: flex;
                            flex-direction: column;

                            // Remove border below the row
                            border: none;

                            // Turn off background highlighting on hovering
                            // over table rows
                            &:hover {
                                background: none;
                            }

                            td {
                                // Remove all paddings from cells, so that
                                // we can align controls as per mocks
                                padding: 0;

                                // Set table cell height as for inputs
                                height: @app-skeleton-input-height;

                                &.resource-name {
                                    // Increase resource name input height to match
                                    // the height of headers used in other tabs
                                    height: @app-skeleton-resource-name-height;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.action-create {
            .app-skeleton-common-properties {
                .table {
                    tbody {
                        tr.errors-row {
                            border-bottom: none;

                            td > .error-message {
                                color: @red;
                            }

                            &:hover{
                                background: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;