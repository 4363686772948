@import (once) "../../app-skeleton.less";

@versions-modal-body-contents-height: 60vh;
@versions-modal-chart-width: 356px;

.app-skeleton-modal {
    &.versions-modal {
        // This additional level is needed. Otherwise the rule below would be
        // applied to all elements in the DOM, as the .app-skeleton-modal and
        // .versions-modal classes are applied to the body, not to the modal
        // DOM element
        .modal {
            .aui-modal-body {
                width: 100%;
            }

            .versions-tab, .comparison-tab {
                position: relative;
                height: @versions-modal-body-contents-height;
                width: 100%;
            }

            .filter-list {
                .aui-text-field {
                    padding-left: 41px + @app-skeleton-table-left-padding;
                }
            }

            .comparison-layout {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .comparison-options {
                display: flex;
                padding: (@md / 2) @lg (@md / 2);
            }

            .toggle-selector {
                border: none;
                padding: 0 @md 0 0;

                .option-label {
                    padding-right: @sm;
                }
            }

            .version-dropdowns {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                > span {
                    padding: 0 @sm;
                }

                .aui-dropdown-selector {
                    height: @lg;
                }

                .dropdown-toggle {
                    background-color: @gray1;
                }
            }

            .scroll-wrapper {
                position: relative;
                height: 100%;
            }

            pre {
                padding: @sm @md;
                // Accounting for right padding 100% set below
                width: 200%;
                overflow-x: hidden;

                // Somehow without a negative margin the pre is couple pixels off
                // the modal container. Couldn't found what was the reason for
                // that, but the margin helped
                margin-right: -@md;

                .diff-level {
                    padding-left: 1em;
                }

                ins, del {
                    text-decoration: none;
                    display: inline-block;

                    // Thanks to this three lines we can extend the backgrunds to the
                    // both edges of the modal, as the mocks want
                    margin-left: -100%;
                    padding-left: 100%;
                    padding-right: 100%;
                }

                ins {
                    background-color: fadeout(@green, 90%);
                }

                del {
                    background-color: fadeout(@red, 90%);
                }
            }

            .compare-charts {
                display: flex;
                flex-direction: column;
                height: 100%;
                border-top: 1px solid @gray3;
            }

            aui-viz-renderer {
                flex-grow: 1;
                height: 50%;

                .aui-chrome {
                    // Clear border shadow and add radius
                    box-shadow: none;

                    .title {
                        // Hide title bar
                        display: none;
                    }

                    .body {
                        // Expand body to take up the full container
                        height: 100%;
                    }
                }

                &:first-child {
                    border-bottom: 1px solid @gray3;
                }
            }

            // Here are overrides for stacking charts horizontally. We do that when
            // the modal crosses some agreed aspect ratio (height < 500px, the
            // check is in the JS file)
            .flat {
                &.compare-charts {
                    flex-direction: row;
                }

                aui-viz-renderer {
                    height: 100%;

                    .aui-chrome {
                        .body {
                            // Set fixed width, as somehow the chart cannot scale it
                            // well when we try to display it in a portait container
                            width: @versions-modal-chart-width;
                        }

                        .visualization {
                            // Set fixed width, as somehow the chart cannot scale it
                            // well when we try to display it in a portait container
                            width: @versions-modal-chart-width;
                        }
                    }

                    &:first-child {
                        border-bottom: none;
                        border-right: 1px solid @gray3;
                    }
                }
            }

            .loading {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    .text-delta;
                    padding-top: @lg;
                    color: @gray5;
                    text-align: center;
                }

                aui-spinner {
                    height: auto;

                    circle {
                        stroke: @blue;
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;