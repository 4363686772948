@import (reference) "../../../app-skeleton.less";

@app-skeleton-cube-derived-property-side-gap: 12px;

.app-skeleton-cube-derived-property {
    .function-header {
        display: flex;
        align-items: center;
        background-color: @gray1;
        padding: 0 @app-skeleton-cube-derived-property-side-gap + 5px;

        .property-function-selector {
            .dropdown-menu {
                width: 360px;

                .aui-dropdown-list {
                    max-height: 30em;
                }
            }
        }
    }

    .function-parameters {
        position: relative;
        height: 50vh;
        padding: @app-skeleton-cube-derived-property-side-gap 20px 0;

        label {
            padding: 15px @app-skeleton-cube-derived-property-side-gap 8px @app-skeleton-cube-derived-property-side-gap;
        }

        .warning-text {
            padding-left: @app-skeleton-cube-derived-property-side-gap;
        }

        .aui-dropdown-selector {
            button {
                padding-left: @app-skeleton-cube-derived-property-side-gap;
            }
        }

        .table {
            table-layout: fixed;

            th, td {
                height: @app-skeleton-input-height;
            }

            th:not(:last-child) {
                padding-left: @app-skeleton-cube-derived-property-side-gap;
            }

            td:not(:last-child) {
                padding-left: 0;

                &.pad-plain-text {
                    padding-left: @app-skeleton-cube-derived-property-side-gap;
                }
            }

            th:last-child, td:last-child {
                &.action-icon {
                    width: @app-skeleton-table-row-action-icon-width;

                    > div {
                        // This is needed for the icon to take up full height
                        // of the cell. This additional div comes from the code
                        // that chooses a template URL to render in a given
                        // table cell
                        height: 100%;
                    }
                }
            }

            td {
                .aui-text-field {
                    height: @app-skeleton-input-height;

                    &:not(:focus) {
                        &.ng-valid, &.ng-untouched {
                            border-bottom: 1px solid transparent;
                        }
                    }
                }

                .text {
                    .text-overflow;
                    padding-left: @app-skeleton-cube-derived-property-side-gap;
                }

                // Two levels of selector needed to override what the app
                // skeleton is setting by default
                &.action-icon {
                    .aui-icon-button {
                        height: @app-skeleton-input-height;
                    }
                }
            }
        }

        .button-table-row {
            button {
                padding: 0 @app-skeleton-cube-derived-property-side-gap;
            }
        }

        .toggle-selector {
            margin: 15px 0 8px 0;
            padding: 0 @app-skeleton-cube-derived-property-side-gap;
        }

        .app-skeleton-cube-property-function-default-return-value,
        .app-skeleton-cube-property-filter-arguments {
            .scroll-padding-bottom;
        }

        .in-utc {
            font-size: 11px;
            padding-left: @app-skeleton-cube-derived-property-side-gap;
        }
    }
}

@enable-css-reset: false;