.dndDraggingSource {
  opacity: 0.3;
}
.dndDragging {
  opacity: 0.7;
}
.dndPlaceholderVertical {
  background-color: #3393DF;
  height: 2px;
}
.dndPlaceholderHorizontal {
  background-color: #3393DF;
  width: 2px;
}
/* stylelint-disable */
@font-face {
  font-family: 'icomoon';
  src: url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/icomoon.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-aad-logo .path1:before {
  content: "\e900";
  color: #59b4d9;
}
.icon-aad-logo .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: #ffffff;
}
.icon-aad-logo .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: #ffffff;
  opacity: 0.5;
}
.icon-aad-logo .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: #ffffff;
  opacity: 0.5;
}
.icon-aad-logo .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: #b8d432;
}
.icon-aad-logo .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: #b8d432;
}
.icon-aad-logo .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: #b8d432;
}
.icon-aad-logo .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: #b8d432;
}
.icon-aad-logo .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: #ffffff;
  opacity: 0.1;
}
.icon-access:before {
  content: "\e909";
}
.icon-accessibility:before {
  content: "\e90a";
}
.icon-account:before {
  content: "\e90b";
}
.icon-activate:before {
  content: "\e90c";
}
.icon-agreement:before {
  content: "\e90d";
}
.icon-alert:before {
  content: "\e90e";
}
.icon-annotation:before {
  content: "\e90f";
}
.icon-app-avocado:before {
  content: "\e910";
}
.icon-app-control-tower:before {
  content: "\e911";
}
.icon-app-event-analytics:before {
  content: "\e912";
}
.icon-app-event-explorer:before {
  content: "\e913";
}
.icon-app-event-inspector:before {
  content: "\e914";
}
.icon-app-event-logger:before {
  content: "\e915";
}
.icon-app-event-troubleshooter:before {
  content: "\e916";
}
.icon-app-execution-insights:before {
  content: "\e917";
}
.icon-app-health-monitor:before {
  content: "\e918";
}
.icon-app-home-page:before {
  content: "\e919";
}
.icon-application:before {
  content: "\e91a";
}
.icon-app-maestro:before {
  content: "\e91b";
}
.icon-app-net-promoter-score:before {
  content: "\e91c";
}
.icon-app-office-data-insights:before {
  content: "\e91d";
}
.icon-app-performance-counters:before {
  content: "\e91e";
}
.icon-app-retention-analytics:before {
  content: "\e91f";
}
.icon-app-search-data-api:before {
  content: "\e920";
}
.icon-app-shared-data:before {
  content: "\e921";
}
.icon-app-siphon:before {
  content: "\e922";
}
.icon-app-smart-advisor:before {
  content: "\e923";
}
.icon-app-spark:before {
  content: "\e924";
}
.icon-app-start-now:before {
  content: "\e925";
}
.icon-app-storage-analytics:before {
  content: "\e926";
}
.icon-app-telemetry-analytics:before {
  content: "\e927";
}
.icon-app-user-analytics:before {
  content: "\e928";
}
.icon-app-visualizations:before {
  content: "\e929";
}
.icon-app-vivace:before {
  content: "\e92a";
}
.icon-area:before {
  content: "\e92b";
}
.icon-aria:before {
  content: "\e92c";
}
.icon-aria-favicon .path1:before {
  content: "\e92d";
  color: #000000;
}
.icon-aria-favicon .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: #29abe2;
}
.icon-aria-icon-black:before {
  content: "\e92f";
}
.icon-aria-icon-inverse .path1:before {
  content: "\e930";
  color: #ffffff;
}
.icon-aria-icon-inverse .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: #29abe2;
}
.icon-aria-icon-normal .path1:before {
  content: "\e932";
  color: #000000;
}
.icon-aria-icon-normal .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: #29abe2;
}
.icon-aria-icon-white:before {
  content: "\e934";
  color: #fff;
}
.icon-aria-logo-black:before {
  content: "\e935";
}
.icon-aria-logo-inverse .path1:before {
  content: "\e936";
  color: #ffffff;
}
.icon-aria-logo-inverse .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: #ffffff;
}
.icon-aria-logo-inverse .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: #ffffff;
}
.icon-aria-logo-inverse .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: #ffffff;
}
.icon-aria-logo-inverse .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: #29abe2;
}
.icon-aria-logo-normal .path1:before {
  content: "\e93b";
  color: #000000;
}
.icon-aria-logo-normal .path2:before {
  content: "\e93c";
  margin-left: -1em;
  color: #000000;
}
.icon-aria-logo-normal .path3:before {
  content: "\e93d";
  margin-left: -1em;
  color: #000000;
}
.icon-aria-logo-normal .path4:before {
  content: "\e93e";
  margin-left: -1em;
  color: #000000;
}
.icon-aria-logo-normal .path5:before {
  content: "\e93f";
  margin-left: -1em;
  color: #29abe2;
}
.icon-aria-logo-white:before {
  content: "\e940";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e941";
}
.icon-arrow-left:before {
  content: "\e942";
}
.icon-arrow-right:before {
  content: "\e943";
}
.icon-arrow-up:before {
  content: "\e944";
}
.icon-article:before {
  content: "\e945";
}
.icon-attach:before {
  content: "\e946";
}
.icon-attention:before {
  content: "\e947";
}
.icon-automatic:before {
  content: "\e948";
}
.icon-axis-x:before {
  content: "\e949";
}
.icon-axis-y:before {
  content: "\e94a";
}
.icon-back:before {
  content: "\e94b";
}
.icon-banding-x:before {
  content: "\e94c";
}
.icon-banding-y:before {
  content: "\e94d";
}
.icon-bar:before {
  content: "\e94e";
}
.icon-basket:before {
  content: "\e94f";
}
.icon-basket-add:before {
  content: "\e950";
}
.icon-binding:before {
  content: "\e951";
}
.icon-blank:before {
  content: "\e952";
}
.icon-blocks:before {
  content: "\e953";
}
.icon-blog:before {
  content: "\e954";
}
.icon-bold:before {
  content: "\e955";
}
.icon-bookmark:before {
  content: "\e956";
}
.icon-breadcrumb-arrow:before {
  content: "\e957";
}
.icon-breadcrumb-pipe:before {
  content: "\e958";
}
.icon-broadcast:before {
  content: "\e959";
}
.icon-bubble:before {
  content: "\e95a";
}
.icon-calculator:before {
  content: "\e95b";
}
.icon-candle-stick:before {
  content: "\e95c";
}
.icon-carousel-left:before {
  content: "\e95d";
}
.icon-carousel-right:before {
  content: "\e95e";
}
.icon-Chart-type .path1:before {
  content: "\e95f";
  color: #f8f8f8;
}
.icon-Chart-type .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: #a5a5a5;
}
.icon-chart:before {
  content: "\e961";
}
.icon-check-all:before {
  content: "\e962";
}
.icon-checkbox-no:before {
  content: "\e963";
}
.icon-checkbox-yes:before {
  content: "\e964";
}
.icon-checkmark:before {
  content: "\e965";
}
.icon-check-none:before {
  content: "\e966";
}
.icon-circle-down:before {
  content: "\e967";
}
.icon-circle-filled:before {
  content: "\e968";
}
.icon-circle-hollow:before {
  content: "\e969";
}
.icon-circle-left:before {
  content: "\e96a";
}
.icon-circle-right:before {
  content: "\e96b";
}
.icon-circle-up:before {
  content: "\e96c";
}
.icon-clear:before {
  content: "\e96d";
}
.icon-clipboard:before {
  content: "\e96e";
}
.icon-clone:before {
  content: "\e96f";
}
.icon-close:before {
  content: "\e970";
}
.icon-code:before {
  content: "\e971";
}
.icon-code-block:before {
  content: "\e972";
}
.icon-collapse:before {
  content: "\e973";
}
.icon-collect:before {
  content: "\e974";
}
.icon-column:before {
  content: "\e975";
}
.icon-combo:before {
  content: "\e976";
}
.icon-comment:before {
  content: "\e977";
}
.icon-compare:before {
  content: "\e978";
}
.icon-config:before {
  content: "\e979";
}
.icon-connect:before {
  content: "\e97a";
}
.icon-connector:before {
  content: "\e97b";
}
.icon-container:before {
  content: "\e97c";
}
.icon-create:before {
  content: "\e97d";
}
.icon-cube:before {
  content: "\e97e";
}
.icon-cube-composite:before {
  content: "\e97f";
}
.icon-dashboard:before {
  content: "\e980";
}
.icon-database:before {
  content: "\e981";
}
.icon-data-grid:before {
  content: "\e982";
}
.icon-date:before {
  content: "\e983";
}
.icon-date-span:before {
  content: "\e984";
}
.icon-decline:before {
  content: "\e985";
}
.icon-default:before {
  content: "\e986";
}
.icon-delete:before {
  content: "\e987";
}
.icon-delta:before {
  content: "\e988";
}
.icon-demo:before {
  content: "\e989";
}
.icon-description:before {
  content: "\e98a";
}
.icon-detail:before {
  content: "\e98b";
}
.icon-dev-center:before {
  content: "\e98c";
}
.icon-dev-tools:before {
  content: "\e98d";
}
.icon-dimension:before {
  content: "\e98e";
}
.icon-discussion:before {
  content: "\e98f";
}
.icon-disperse:before {
  content: "\e990";
}
.icon-display:before {
  content: "\e991";
}
.icon-divider:before {
  content: "\e992";
}
.icon-document:before {
  content: "\e993";
}
.icon-double-down:before {
  content: "\e994";
}
.icon-double-left:before {
  content: "\e995";
}
.icon-double-right:before {
  content: "\e996";
}
.icon-double-up:before {
  content: "\e997";
}
.icon-doughnut:before {
  content: "\e998";
}
.icon-download:before {
  content: "\e999";
}
.icon-drill-in:before {
  content: "\e99a";
}
.icon-drill-out:before {
  content: "\e99b";
}
.icon-drop-down:before {
  content: "\e99c";
}
.icon-edit:before {
  content: "\e99d";
}
.icon-editor:before {
  content: "\e99e";
}
.icon-embed:before {
  content: "\e99f";
}
.icon-empty:before {
  content: "\e9a0";
}
.icon-enlarge:before {
  content: "\e9a1";
}
.icon-Error .path1:before {
  content: "\e9a2";
  color: #f8f8f8;
}
.icon-Error .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: #a5a5a5;
}
.icon-event:before {
  content: "\e9a4";
}
.icon-excel:before {
  content: "\e9a5";
}
.icon-expand:before {
  content: "\e9a6";
}
.icon-experiment:before {
  content: "\e9a7";
}
.icon-export:before {
  content: "\e9a8";
}
.icon-expression:before {
  content: "\e9a9";
}
.icon-external:before {
  content: "\e9aa";
}
.icon-favorite-on:before {
  content: "\e9ab";
}
.icon-feedback:before {
  content: "\e9ac";
}
.icon-file-csv:before {
  content: "\e9ad";
}
.icon-file-png:before {
  content: "\e9ae";
}
.icon-file-svg:before {
  content: "\e9af";
}
.icon-filter:before {
  content: "\e9b0";
}
.icon-flows:before {
  content: "\e9b1";
}
.icon-folder:before {
  content: "\e9b2";
}
.icon-follow:before {
  content: "\e9b3";
}
.icon-forward:before {
  content: "\e9b4";
}
.icon-funnel:before {
  content: "\e9b5";
}
.icon-gallery:before {
  content: "\e9b6";
}
.icon-gauge:before {
  content: "\e9b7";
}
.icon-graph:before {
  content: "\e9b8";
}
.icon-group:before {
  content: "\e9b9";
}
.icon-growth:before {
  content: "\e9ba";
}
.icon-hand .path1:before {
  content: "\e9bb";
  color: #000000;
}
.icon-hand .path2:before {
  content: "\e9bc";
  margin-left: -1em;
  color: #ffffff;
}
.icon-heading1:before {
  content: "\e9bd";
}
.icon-heading2:before {
  content: "\e9be";
}
.icon-heading3:before {
  content: "\e9bf";
}
.icon-health:before {
  content: "\e9c0";
}
.icon-health-green:before {
  content: "\e9c1";
}
.icon-health-metric:before {
  content: "\e9c2";
}
.icon-health-red:before {
  content: "\e9c3";
}
.icon-health-yellow:before {
  content: "\e9c4";
}
.icon-heat-map:before {
  content: "\e9c5";
}
.icon-help:before {
  content: "\e9c6";
}
.icon-hexagon:before {
  content: "\e9c7";
}
.icon-histogram:before {
  content: "\e9c8";
}
.icon-history:before {
  content: "\e9c9";
}
.icon-home:before {
  content: "\e9ca";
}
.icon-horizontal-line:before {
  content: "\e9cb";
}
.icon-hotkeys:before {
  content: "\e9cc";
}
.icon-icon-ios-android-device:before {
  content: "\e9cd";
}
.icon-icon-ios-apple-device:before {
  content: "\e9ce";
}
.icon-icon-ios-application:before {
  content: "\e9cf";
}
.icon-icon-ios-chart:before {
  content: "\e9d0";
}
.icon-icon-ios-code:before {
  content: "\e9d1";
}
.icon-icon-ios-cube:before {
  content: "\e9d2";
}
.icon-icon-ios-dashboard:before {
  content: "\e9d3";
}
.icon-icon-ios-folder:before {
  content: "\e9d4";
}
.icon-icon-ios-inspect:before {
  content: "\e9d5";
}
.icon-icon-ios-read:before {
  content: "\e9d6";
}
.icon-idea:before {
  content: "\e9d7";
}
.icon-identifier:before {
  content: "\e9d8";
}
.icon-import:before {
  content: "\e9d9";
}
.icon-information:before {
  content: "\e9da";
}
.icon-ingestion:before {
  content: "\e9db";
}
.icon-inherit:before {
  content: "\e9dc";
}
.icon-insert:before {
  content: "\e9dd";
}
.icon-inspect:before {
  content: "\e9de";
}
.icon-install:before {
  content: "\e9df";
}
.icon-intelligence:before {
  content: "\e9e0";
}
.icon-invisible:before {
  content: "\e9e1";
}
.icon-italic:before {
  content: "\e9e2";
}
.icon-json:before {
  content: "\e9e3";
}
.icon-key:before {
  content: "\e9e4";
}
.icon-label:before {
  content: "\e9e5";
}
.icon-layers:before {
  content: "\e9e6";
}
.icon-layout:before {
  content: "\e9e7";
}
.icon-learn:before {
  content: "\e9e8";
}
.icon-legacy:before {
  content: "\e9e9";
}
.icon-line:before {
  content: "\e9ea";
}
.icon-link:before {
  content: "\e9eb";
}
.icon-list:before {
  content: "\e9ec";
}
.icon-listen:before {
  content: "\e9ed";
}
.icon-location:before {
  content: "\e9ee";
}
.icon-lock:before {
  content: "\e9ef";
}
.icon-mail:before {
  content: "\e9f0";
}
.icon-map:before {
  content: "\e9f1";
}
.icon-math:before {
  content: "\e9f2";
}
.icon-maximize:before {
  content: "\e9f3";
}
.icon-measure:before {
  content: "\e9f4";
}
.icon-menu:before {
  content: "\e9f5";
}
.icon-merge:before {
  content: "\e9f6";
}
.icon-meter:before {
  content: "\e9f7";
}
.icon-metric-tile:before {
  content: "\e9f8";
}
.icon-microsoft_icon .path1:before {
  content: "\e9f9";
  color: #e15d29;
}
.icon-microsoft_icon .path2:before {
  content: "\e9fa";
  margin-left: -1em;
  color: #84bf41;
}
.icon-microsoft_icon .path3:before {
  content: "\e9fb";
  margin-left: -1em;
  color: #00a3da;
}
.icon-microsoft_icon .path4:before {
  content: "\e9fc";
  margin-left: -1em;
  color: #f6bc25;
}
.icon-minus:before {
  content: "\e9fd";
}
.icon-more:before {
  content: "\e9fe";
}
.icon-move:before {
  content: "\e9ff";
}
.icon-name:before {
  content: "\ea00";
}
.icon-new:before {
  content: "\ea01";
}
.icon-new-tab-blank:before {
  content: "\ea02";
  color: #595959;
}
.icon-new-tab-featured:before {
  content: "\ea03";
  color: #595959;
}
.icon-new-tab-report:before {
  content: "\ea04";
  color: #595959;
}
.icon-new-tab-single-col:before {
  content: "\ea05";
  color: #595959;
}
.icon-new-tab-three-col:before {
  content: "\ea06";
  color: #595959;
}
.icon-new-tab-two-col:before {
  content: "\ea07";
  color: #595959;
}
.icon-note:before {
  content: "\ea08";
}
.icon-notebook:before {
  content: "\ea09";
}
.icon-notification:before {
  content: "\ea0a";
}
.icon-number:before {
  content: "\ea0b";
}
.icon-onboarding-bar-chart:before {
  content: "\ea0c";
}
.icon-onboarding-code:before {
  content: "\ea0d";
}
.icon-onboarding-monitor:before {
  content: "\ea0e";
}
.icon-operator:before {
  content: "\ea0f";
}
.icon-option-off:before {
  content: "\ea10";
}
.icon-option-on:before {
  content: "\ea11";
}
.icon-ordered-list:before {
  content: "\ea12";
}
.icon-organization:before {
  content: "\ea13";
}
.icon-overview:before {
  content: "\ea14";
}
.icon-page-first:before {
  content: "\ea15";
}
.icon-page-last:before {
  content: "\ea16";
}
.icon-page-next:before {
  content: "\ea17";
}
.icon-page-previous:before {
  content: "\ea18";
}
.icon-palette:before {
  content: "\ea19";
}
.icon-pause:before {
  content: "\ea1a";
}
.icon-phone:before {
  content: "\ea1b";
}
.icon-pie:before {
  content: "\ea1c";
}
.icon-pin-off:before {
  content: "\ea1d";
}
.icon-pin-on:before {
  content: "\ea1e";
}
.icon-play:before {
  content: "\ea1f";
}
.icon-pointer:before {
  content: "\ea20";
}
.icon-poll:before {
  content: "\ea21";
}
.icon-power-bi:before {
  content: "\ea22";
}
.icon-process:before {
  content: "\ea23";
}
.icon-project:before {
  content: "\ea24";
}
.icon-property:before {
  content: "\ea25";
}
.icon-query:before {
  content: "\ea26";
}
.icon-quote-block:before {
  content: "\ea27";
}
.icon-read:before {
  content: "\ea28";
}
.icon-redo:before {
  content: "\ea29";
}
.icon-reference:before {
  content: "\ea2a";
}
.icon-refresh:before {
  content: "\ea2b";
}
.icon-region:before {
  content: "\ea2c";
}
.icon-rename:before {
  content: "\ea2d";
}
.icon-reorder:before {
  content: "\ea2e";
}
.icon-replace:before {
  content: "\ea2f";
}
.icon-reply:before {
  content: "\ea30";
}
.icon-report:before {
  content: "\ea31";
}
.icon-reset:before {
  content: "\ea32";
}
.icon-reset-auto:before {
  content: "\ea33";
}
.icon-resize:before {
  content: "\ea34";
}
.icon-resize-diagonal:before {
  content: "\ea35";
}
.icon-resize-horizontal:before {
  content: "\ea36";
}
.icon-resize-vertical:before {
  content: "\ea37";
}
.icon-resolve:before {
  content: "\ea38";
}
.icon-restore:before {
  content: "\ea39";
}
.icon-sad:before {
  content: "\ea3a";
}
.icon-save:before {
  content: "\ea3b";
}
.icon-save-as:before {
  content: "\ea3c";
}
.icon-scatter:before {
  content: "\ea3d";
}
.icon-screenshot:before {
  content: "\ea3e";
}
.icon-search:before {
  content: "\ea3f";
}
.icon-section:before {
  content: "\ea40";
}
.icon-select:before {
  content: "\ea41";
}
.icon-selection-no:before {
  content: "\ea42";
}
.icon-selection-yes:before {
  content: "\ea43";
}
.icon-send:before {
  content: "\ea44";
}
.icon-sequence:before {
  content: "\ea45";
}
.icon-series:before {
  content: "\ea46";
}
.icon-service:before {
  content: "\ea47";
}
.icon-settings:before {
  content: "\ea48";
}
.icon-share:before {
  content: "\ea49";
}
.icon-shrink:before {
  content: "\ea4a";
}
.icon-sidebar-left:before {
  content: "\ea4b";
}
.icon-sidebar-right:before {
  content: "\ea4c";
}
.icon-sigma:before {
  content: "\ea4d";
}
.icon-signal:before {
  content: "\ea4e";
}
.icon-sign-in:before {
  content: "\ea4f";
}
.icon-sign-out:before {
  content: "\ea50";
}
.icon-simplify:before {
  content: "\ea51";
}
.icon-slide-down:before {
  content: "\ea52";
}
.icon-slide-up:before {
  content: "\ea53";
}
.icon-smiley:before {
  content: "\ea54";
}
.icon-snapshot:before {
  content: "\ea55";
}
.icon-sort-asc:before {
  content: "\ea56";
}
.icon-sort-az:before {
  content: "\ea57";
}
.icon-sort-desc:before {
  content: "\ea58";
}
.icon-sort-none:before {
  content: "\ea59";
}
.icon-sort-za:before {
  content: "\ea5a";
}
.icon-source:before {
  content: "\ea5b";
}
.icon-spinner:before {
  content: "\ea5c";
}
.icon-split:before {
  content: "\ea5d";
}
.icon-star:before {
  content: "\ea5e";
}
.icon-star-filled:before {
  content: "\ea5f";
}
.icon-start:before {
  content: "\ea60";
}
.icon-statistics:before {
  content: "\ea61";
}
.icon-status-green:before {
  content: "\ea62";
}
.icon-status-red:before {
  content: "\ea63";
}
.icon-status-yellow:before {
  content: "\ea64";
}
.icon-stop:before {
  content: "\ea65";
}
.icon-stopwatch:before {
  content: "\ea66";
}
.icon-string:before {
  content: "\ea67";
}
.icon-subscriber:before {
  content: "\ea68";
}
.icon-support:before {
  content: "\ea69";
}
.icon-tab:before {
  content: "\ea6a";
}
.icon-table:before {
  content: "\ea6b";
}
.icon-table-cell:before {
  content: "\ea6c";
}
.icon-taskbar-empty-bookmark .path1:before {
  content: "\ea6d";
  color: #262626;
}
.icon-taskbar-empty-bookmark .path2:before {
  content: "\ea6e";
  margin-left: -1em;
  color: #595959;
}
.icon-taskbar-empty-notebook .path1:before {
  content: "\ea6f";
  color: #262626;
}
.icon-taskbar-empty-notebook .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: #595959;
}
.icon-taskbar-empty-search .path1:before {
  content: "\ea71";
  color: #262626;
}
.icon-taskbar-empty-search .path2:before {
  content: "\ea72";
  margin-left: -1em;
  color: #595959;
}
.icon-taskbar-user-pane .path1:before {
  content: "\ea73";
  color: #404040;
}
.icon-taskbar-user-pane .path2:before {
  content: "\ea74";
  margin-left: -1em;
  color: #cdcdcd;
}
.icon-text:before {
  content: "\ea75";
}
.icon-text-cursor:before {
  content: "\ea76";
}
.icon-thread-active:before {
  content: "\ea77";
}
.icon-thread-resolved:before {
  content: "\ea78";
}
.icon-time:before {
  content: "\ea79";
}
.icon-time-span:before {
  content: "\ea7a";
}
.icon-toggle-off:before {
  content: "\ea7b";
}
.icon-toggle-on:before {
  content: "\ea7c";
}
.icon-tools:before {
  content: "\ea7d";
}
.icon-transform:before {
  content: "\ea7e";
}
.icon-tree:before {
  content: "\ea7f";
}
.icon-tree-closed:before {
  content: "\ea80";
}
.icon-tree-opened:before {
  content: "\ea81";
}
.icon-trend:before {
  content: "\ea82";
}
.icon-type:before {
  content: "\ea83";
}
.icon-underline:before {
  content: "\ea84";
}
.icon-undo:before {
  content: "\ea85";
}
.icon-unlock:before {
  content: "\ea86";
}
.icon-unordered-list:before {
  content: "\ea87";
}
.icon-unpin:before {
  content: "\ea88";
}
.icon-upload:before {
  content: "\ea89";
}
.icon-user:before {
  content: "\ea8a";
}
.icon-users:before {
  content: "\ea8b";
}
.icon-view:before {
  content: "\ea8c";
}
.icon-visible:before {
  content: "\ea8d";
}
.icon-visualization:before {
  content: "\ea8e";
}
.icon-vote-down:before {
  content: "\ea8f";
}
.icon-vote-up:before {
  content: "\ea90";
}
.icon-warning:before {
  content: "\ea91";
}
.icon-web:before {
  content: "\ea92";
}
.icon-welcome:before {
  content: "\ea93";
}
.icon-widget:before {
  content: "\ea94";
}
.icon-zoom-in:before {
  content: "\ea95";
}
.icon-zoom-out:before {
  content: "\ea96";
}
/* stylelint-disable */
[class^="icon-"],
[class*=" icon-"] {
  vertical-align: middle;
}
[class^="icon-"]:hover,
[class*=" icon-"]:hover {
  cursor: pointer;
}
.icon-sm {
  font-size: 10px;
}
.icon-md {
  font-size: 15px;
}
.icon-lg {
  font-size: 20px;
}
.icon-1x {
  font-size: 20px;
}
.icon-2x {
  font-size: 40px;
}
.icon-3x {
  font-size: 60px;
}
.icon-4x {
  font-size: 80px;
}
.icon-5x {
  font-size: 100px;
}
.icon-transparent {
  color: transparent !important;
}
.icon-transparent.hover {
  color: transparent !important;
}
.icon-white {
  color: #FFFFFF;
}
.icon-blue {
  color: #0078D7;
}
.icon-black {
  color: #1E1D1D;
}
.icon-green {
  color: #107C10;
}
.icon-red {
  color: #C50E2E;
}
.icon-yellow {
  color: #FCD116;
}
.icon-orange {
  color: #FF8C00;
}
/* !important attribute ensures that this color takes precedence over on-hover color */
.icon-active {
  color: #107C10 !important;
}
.icon-inactive,
.icon-gray {
  color: #E3E6EC;
}
.icon-gray-darker {
  color: #F5F7FA;
}
.icon-background-yellow {
  background-color: #FCD116;
}
.icon-background-blue {
  background-color: #0078D7;
}
.icon-background-green {
  background-color: #107C10;
}
.icon-background-red {
  background-color: #C50E2E;
}
.icon-background-orange {
  background-color: #FF8C00;
}
/*

FONTS OVERVIEW

A quick summary of font filetypes (eot, svg, ttf, woff, woff2):
    eot, svg, ttf (True Type Font) - Older file formats
    woff, woff2 (Web Open Font Format) - Optimized for web application. They are heavily compressed.

.ttf filetype is the commonly used format (Windows uses it!), and can be easily converted to .woff/.woff2
formats. The .ttf font files were downloaded from the internal Microsoft Brand site (thanks David Dossett!)
https://microsoft.sharepoint.com/teams/BrandCentral/Pages/Bundles/Segoe_UI_fonts.aspx

We have the .ttf(original)/.woff(converted)/.woff2(converted) assets checked in our repo.

ARIA SPECIFIC

All Aria.ms sites (docs/portal) should be using the Microsoft standard Segoe UI font. The Segoe UI font
assets can be found in the Aria.js repo at /src/ui/assets/fonts/segoe/

The font faces, defined below, create font families that can be referenced all over our web app. It will first
try to use the local font installed on the user's machine, and move to the next option if it fails. All Windows
machines have the Segoe UI font installed, these users should NEVER need to download the fonts from the server.
The links to the .woff/.woff2 fonts on the server should be used only by non-Windows users. There are browser
specific bugs right now, but they should be fixed over the course of time.

RESOURCES

Learn more about fonts, font-faces & font file types
    https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face
    https://css-tricks.com/snippets/css/using-font-face/

Easy, simple online font converter
    https://transfonter.org/

WOFF Font Validator
    http://validator.fontbureau.com/

*/
@font-face {
  font-family: 'Segoe UI Regular';
  src: local('Segoe UI Regular'), local('Segoe UI'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-regular.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI Light';
  src: local('Segoe UI Light'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-light.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI Semilight';
  src: local('Segoe UI Semilight'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-semilight.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-semilight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI Bold';
  src: local('Segoe UI Bold'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-bold.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Segoe UI Semibold';
  src: local('Segoe UI Semibold'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-semibold.woff2') format('woff2'), url('../../../../node_modules/@ms-aria/app-fx/src/ui/assets/fonts/segoe/segoeui-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/*
These variables are used for easy experimentation with different fonts for our code base.

Aria developers, please use the font-specific mixins defined in \src\ui\styles\mixins\mixins.less
in your CSS / LESS.
*/
.box-shadow {
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow-neutral {
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow-gray {
  box-shadow: 0 13px 50px 0 rgba(22, 34, 58, 0.2);
}
.box-shadow-center-sm {
  box-shadow: 0 0 8px rgba(29, 59, 81, 0.5);
}
.box-shadow-sm {
  box-shadow: 0 2px 8px rgba(29, 59, 81, 0.16);
}
.box-shadow-md {
  box-shadow: 0 2px 16px rgba(29, 59, 81, 0.16);
}
.box-shadow-lg {
  box-shadow: 0 2px 32px rgba(29, 59, 81, 0.16);
}
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-overflow-table-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
.darken10 {
  background: linear-gradient(rgba(22, 34, 58, 0.1), rgba(22, 34, 58, 0.1));
}
.darken25 {
  background: linear-gradient(rgba(22, 34, 58, 0.25), rgba(22, 34, 58, 0.25));
}
.darken50 {
  background: linear-gradient(rgba(22, 34, 58, 0.5), rgba(22, 34, 58, 0.5));
}
.blue-darken25 {
  background-color: #005aa1;
}
.blue-darken50 {
  background-color: #003c6c;
}
.blue-lighten20 {
  background-color: #0390ff;
}
.blue-lighten40 {
  background-color: #2ea3ff;
}
.blue-lighten60 {
  background-color: #59b6ff;
}
.green-darken25 {
  background-color: #0c5d0c;
}
.green-darken50 {
  background-color: #083e08;
}
.green-lighten20 {
  background-color: #139513;
}
.green-lighten40 {
  background-color: #16ae16;
}
.green-lighten60 {
  background-color: #1ac61a;
}
.yellow-darken25 {
  background-color: #cba503;
}
.yellow-darken50 {
  background-color: #876e02;
}
.yellow-lighten20 {
  background-color: #fddc4c;
}
.yellow-lighten40 {
  background-color: #fde682;
}
.yellow-lighten60 {
  background-color: #fef1b8;
}
.orange-darken25 {
  background-color: #bf6900;
}
.orange-darken50 {
  background-color: #804600;
}
.orange-lighten20 {
  background-color: #ffa333;
}
.orange-lighten40 {
  background-color: #ffba66;
}
.orange-lighten60 {
  background-color: #ffd199;
}
.red-darken25 {
  background-color: #940a23;
}
.red-darken50 {
  background-color: #630717;
}
.red-lighten20 {
  background-color: #ec1137;
}
.red-lighten40 {
  background-color: #f13757;
}
.red-lighten60 {
  background-color: #f45e78;
}
.purple-darken25 {
  background-color: #45226d;
}
.purple-darken50 {
  background-color: #2e1749;
}
.purple-lighten20 {
  background-color: #6e36ae;
}
.purple-lighten40 {
  background-color: #8145c5;
}
.purple-lighten60 {
  background-color: #9562ce;
}
.dashboard-flex-layout {
  display: flex;
  flex: 1;
}
.viz-align-center {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.text-field-as-table-cell:not(:focus):not(:hover) {
  border-bottom: 1px solid transparent;
}
.text-field-as-table-cell.ng-invalid.ng-touched,
.text-field-as-table-cell.ng-invalid.ng-dirty {
  border-bottom: 1px solid #C50E2E;
}
.smart-analytics-insight-text-styling-mixin {
  padding: 0;
  max-width: 272px;
  max-height: 450px;
  overflow: auto;
}
.smart-analytics-insight-text-styling-mixin ul {
  padding-left: 18px;
}
.smart-analytics-insight-text-styling-mixin th {
  border-bottom: 2px solid #0078D7;
  font-family: 'Segoe UI Bold';
}
.smart-analytics-insight-text-styling-mixin td {
  border-bottom: 1px solid #EEF2F8;
}
.smart-analytics-insight-text-styling-mixin th,
.smart-analytics-insight-text-styling-mixin td {
  overflow-wrap: break-word;
  max-width: 150px;
  padding: 5px;
}
.smart-analytics-insight-text-styling-mixin table {
  table-layout: fixed;
  width: 272px;
}
.smart-analytics-insight-text-styling-mixin span {
  color: #C50E2E;
}
/* font optimization
  Following font optimization from Azure Portal
  Simplified explanation about browser specificities.
  Updated 2017/06

  C = Chrome, E = Edge, IE = Internet Explorer, FF = Firefox, !Win = Anything not on Windows

  font-family: "Segoe UI Light"            C,E,IE
  font-family: "Segoe UI Semilight"        E,IE
  font-family: "Segoe UI"                  C,E,IE,FF
  font-family: "Segoe UI Semibold"         C,E,IE
  font-family: "Segoe UI Bold"             E

  @font-face { src: local("Segoe UI Light") }          C,E,IE,FF
  @font-face { src: local("Segoe UI Semilight") }      E,IE,FF
  @font-face { src: local("Segoe UI") }                C,E,IE,FF
  @font-face { src: local("Segoe UI Semibold") }       C,E,IE,FF
  @font-face { src: local("Segoe UI Bold") }           E,IE,FF

  "Segoe UI Light" as local("Segoe UI") + font-weight: 200         C,E,IE,FF
  "Segoe UI Semilight" as local("Segoe UI") + font-weight: 300     E,IE,FF
  "Segoe UI" as local("Segoe UI") + font-weight: 400               C,E,IE,FF
  "Segoe UI Semibold" as local("Segoe UI") + font-weight: 600      C,E,IE,FF
  "Segoe UI Bold" as local("Segoe UI") + font-weight: 700          C,E,IE,FF

  @font-face { src: url([...]) } for any font (downloading it)     C,E,IE,FF,!Win

  Conclusions : local("Segoe UI") + font-weight: X can render all fonts except Segoe UI Semilight
                there is no way to render Segoe UI Semilight in Chrome without downloading it

  Fonts are being downloaded on an average of 38% of Portal sessions as of 2017/06.
  A combination of using the right local system font, falling back to local Segoe UI + right font-weight
  and removing usage of Segoe UI Semilight guarantees any Windows user won't have to download 3 font files
  (the average number of fonts needed on any dashboard/blade) meaning 250kb (compressed) on startup.

  Note : we serve font files ourselves because the ones served by c.s-microsoft.com, used by microsoft.com,
  don't have sufficiently good cache headers and would require 304s on every load of the Portal.


  az_ea_font is defined as a placeholder to capture the East Asian font when provided.
  Dynamically inject that dependency in the <head> element depending which variant is desired (ex: zh-hant/zh-hans)
  Keep Segoe UI first, otherwise, the numbers and latin text does not match the desired output.


  Additional details about font families for Segoe UI
  wf_segoe-ui_* should point to the correct character sets by downloading the appropriate locale.
  Otherwise, platform other than Windows will not get the Segoe UI experience. Windows has the font pre-installed, so this gets overlooked.
  http://mscom-adoption/sitemanagement/web-font-repository.aspx

  Note : Chome 62 introduced a font-face regression, so "Segoe UI" + font-weight is now attempted before "wf_segoe-ui".
  More details about the regression at https://bugs.chromium.org/p/chromium/issues/detail?id=792271
*/
.aria-regular {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
}
.aria-light {
  font-family: "Segoe UI", wf_segoe-ui_light, "Segoe UI Light", "Segoe WP Light", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 200;
}
.aria-semi-light {
  font-family: "Segoe UI", wf_segoe-ui_semilight, "Segoe UI Light", "Segoe WP Light", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 300;
}
.aria-bold {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
}
.aria-semi-bold {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
}
.aria-icomoon {
  font-family: 'icomoon';
}
.aria-font-size-standard {
  font-size: 14px;
}
.aria-font-size-c3 {
  font-size: 12px;
}
.text-giga {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.125em;
}
.text-mega {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.125em;
}
.text-alpha {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.125em;
}
.text-beta {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.125em;
}
.text-gamma {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.125em;
}
.text-delta {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.125em;
}
.text-epsilon {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.125em;
}
.text-zeta {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.text-paragraph {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
}
.text-body {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
}
.text-caption {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5em;
}
.scroll-padding-bottom {
  padding-bottom: 100px;
}
.dropdown-toggle-hover-focus:hover,
.dropdown-toggle-hover-focus:focus {
  color: #FFFFFF;
  background: linear-gradient(rgba(22, 34, 58, 0.25), rgba(22, 34, 58, 0.25));
  cursor: pointer;
}
.dropdown-toggle-hover-focus:hover i,
.dropdown-toggle-hover-focus:focus i {
  color: #FFFFFF;
}
.disabled {
  color: #606B72;
}
.darken10 {
  background: linear-gradient(rgba(22, 34, 58, 0.1), rgba(22, 34, 58, 0.1));
}
.darken25 {
  background: linear-gradient(rgba(22, 34, 58, 0.25), rgba(22, 34, 58, 0.25));
}
.flex-layout-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.border-radius {
  border-radius: 2px;
}
body {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  background-color: #FFFFFF;
}
h1 {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.125em;
  color: #282727;
}
h2 {
  font-family: "Segoe UI", wf_segoe-ui_bold, "Segoe UI Bold", "Segoe WP Bold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.125em;
  color: #282727;
}
h3 {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.125em;
  color: #282727;
}
h4 {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.125em;
  color: #282727;
}
h5 {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.125em;
  color: #282727;
}
h6 {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #282727;
}
p {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5em;
  color: #282727;
}
i {
  -webkit-text-stroke: initial;
}
a {
  color: #0078D7;
}
a:hover,
a:focus {
  color: #0078D7;
  text-decoration: none;
}
a:hover[target="_blank"],
a:focus[target="_blank"] {
  text-decoration: underline;
}
ol {
  padding-left: 32px;
  color: #282727;
}
ul {
  padding-left: 32px;
  list-style: round;
}
li {
  line-height: 1.5em;
}
b {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
}
pre {
  font-family: Consolas;
  font-size: 14px;
  line-height: 1.5em;
  padding: 24px;
  border: none;
  border-radius: 2px;
  background-color: rgba(57, 105, 153, 0.05);
}
textarea {
  font-size: 14px;
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  color: #282727;
  padding: 6px 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #8C9AA3;
  width: 100%;
}
textarea:focus {
  border-bottom: 2px solid #0078D7;
}
textarea[disabled],
textarea[readonly] {
  color: #606B72;
  cursor: not-allowed;
  background-color: inherit;
}
label,
.label {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
strong {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
}
.warning-text {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #C50E2E;
}
.list-tag {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #0078D7;
  background: transparent;
  border: 1px solid #0078D7;
  height: 24px;
  padding: 0px 8px;
  margin-left: 8px;
  border-radius: 16px;
  align-self: center;
  display: flex;
  align-items: center;
}
.full-height {
  height: 100%;
}
.image-wrapper {
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 50%;
  display: flex;
  height: 200px;
  width: 200px;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
}
.image-wrapper i {
  color: #606B72;
  font-size: 75px;
}
html,
body {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-y: hidden;
}
html .fui-FluentProvider,
body .fui-FluentProvider {
  background: transparent;
}
iframe {
  border: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.content {
  margin: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
}
.content main {
  flex: 1;
  display: flex;
  position: relative;
}
.content main div.viewport {
  position: relative;
  background-color: white;
  overflow: hidden;
  flex: 1;
  display: flex;
}
.content main div.viewport > div.viewport-root {
  position: relative;
  background-color: white;
  overflow: hidden;
  flex: 1;
  display: flex;
}
.content main > .loading-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #EEF2F8;
}
.content main > .loading-page > .spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.content main > .loading-page > .spinner-container > p.header {
  font-family: "Segoe UI", wf_segoe-ui_semibold, "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 21px;
  color: #0078D7;
}
.content main > .loading-page > .spinner-container > p.text {
  font-size: 16px;
  color: #282727;
}
button {
  border-radius: 0;
}
.aui-text-button.link-like {
  color: #0078D7;
  width: 100%;
  text-align: left;
  padding: 0;
}
.aui-text-button.link-like:hover {
  background: transparent;
}
.aui-text-button.link-like.danger {
  color: #C50E2E;
}
.aui-text-button.link-like.neutral {
  color: #282727;
}
.aui-text-button.link-like.opportunity {
  color: #107C10;
}
.aui-dropdown-menu {
  z-index: 1000;
}
@media (prefers-contrast: more) {
  .dropdown-menu > .active > a,
  .aui-dropdown-menu a:active,
  .aui-dropdown-menu a:focus,
  .aui-dropdown-selector a:active,
  .aui-dropdown-selector a:focus,
  .aui-list-item.highlight {
    outline: 2px solid black !important;
  }
}
.aui-popover {
  z-index: 2147483647;
}
.aui-tooltip {
  z-index: 2147483647;
}
.hide-scrollbar div[class^="ps-scrollbar-"] {
  display: none;
}
.app-titlebar aui-icon-button .aui-icon-button.highlight {
  background-color: #C50E2E;
}
.discard-modal .aui-text-button.commit {
  background-color: #C50E2E;
}
.zoom-view {
  z-index: 500;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 44px;
}
.zoom-view.zoomPinned {
  left: 380px;
}
.toggle-selector:focus {
  outline: none;
}
.light-theme *[data-begin-prefers-color-scheme="dark"] {
  filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
}
.light-theme *[data-begin-prefers-color-scheme="dark"] *[data-end-prefers-color-scheme="dark"] {
  filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
}
.dark-theme *[data-begin-prefers-color-scheme="light"],
.dark-theme :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
  filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
}
.dark-theme *[data-begin-prefers-color-scheme="light"] *[data-end-prefers-color-scheme="light"],
.dark-theme :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) *[data-end-prefers-color-scheme="light"] {
  filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
}
.dark-theme *:not([data-begin-prefers-color-scheme="light"]) {
  --dark-theme-box-shadow-color: #f8f8f8;
}
.dark-theme :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
  --dark-theme-box-shadow-color: #080808;
}
@media (prefers-color-scheme: light) {
  body[data-prefers-color-scheme-feature-enabled="true"]:not([data-prefers-color-scheme="dark"]) *[data-begin-prefers-color-scheme="dark"] {
    filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
  }
  body[data-prefers-color-scheme-feature-enabled="true"]:not([data-prefers-color-scheme="dark"]) *[data-begin-prefers-color-scheme="dark"] *[data-end-prefers-color-scheme="dark"] {
    filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
  }
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="light"] *[data-begin-prefers-color-scheme="dark"] {
  filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="light"] *[data-begin-prefers-color-scheme="dark"] *[data-end-prefers-color-scheme="dark"] {
  filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
}
@media (prefers-color-scheme: dark) {
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] {
    filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
  }
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] *[data-begin-prefers-color-scheme="light"],
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
    filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
  }
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] *[data-begin-prefers-color-scheme="light"] *[data-end-prefers-color-scheme="light"],
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) *[data-end-prefers-color-scheme="light"] {
    filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
  }
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] *:not([data-begin-prefers-color-scheme="light"]) {
    --dark-theme-box-shadow-color: #f8f8f8;
  }
  body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
    --dark-theme-box-shadow-color: #080808;
  }
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] {
  filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] *[data-begin-prefers-color-scheme="light"],
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
  filter: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] *[data-begin-prefers-color-scheme="light"] *[data-end-prefers-color-scheme="light"],
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) *[data-end-prefers-color-scheme="light"] {
  filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] *:not([data-begin-prefers-color-scheme="light"]) {
  --dark-theme-box-shadow-color: #f8f8f8;
}
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
  --dark-theme-box-shadow-color: #080808;
}
.data-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.data-app app-titlebar {
  flex: 0 0 auto;
}
.data-app app-titlebar .right-action-items {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
}
.data-app app-titlebar .right-action-items .icon-save.pristine {
  color: #005aa1;
}
.data-app app-titlebar .right-action-items .aui-icon-toolbar {
  flex-grow: 0;
}
.data-app .application {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  overflow: hidden;
  width: 100%;
}
.data-app .application .main-pane {
  flex: 1 1 calc(100% - 350px);
  min-width: calc(100% - 350px);
  height: 100%;
}
.data-app .application .sidebar {
  max-width: 350px;
  flex: 0 0 350px;
  background-color: #F3F2F1;
  border-left: 1px solid #E1DFDD;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.data-app .application .sidebar aui-accordion .panel-group .panel .panel-heading {
  background-color: #EDEBE9;
}
.data-app .application .sidebar aui-accordion .aui-list-item:hover {
  background-color: #EDEBE9;
}
@media screen and (max-width: 800px) and (max-height: 600px) {
  .data-app .application {
    flex-direction: column;
    overflow-y: scroll;
  }
  .data-app .application .main-pane {
    width: 100%;
    height: auto;
  }
  .data-app .application .sidebar {
    width: 100%;
    max-height: 100px;
  }
}
.scroll-padding-bottom {
  padding-bottom: 100px;
}
.input-form.creator-form {
  flex-direction: column;
  width: 100%;
}
.input-form.creator-form h2 {
  margin: 0;
}
.input-form.creator-form p {
  font-size: 16px;
  margin: 20px 0;
  max-width: 420px;
  text-align: center;
}
.input-form.creator-form .image-wrapper {
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 50%;
  display: flex;
  height: 200px;
  width: 200px;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
}
.input-form.creator-form .image-wrapper i {
  color: #606B72;
  font-size: 75px;
}
.input-form.creator-form label:not(.fui-Label) {
  width: 320px;
  text-transform: uppercase;
  align-items: center;
  padding-left: 10px;
  margin-top: 10px;
}
.input-form.creator-form .create-input {
  color: #1E1D1D;
  width: 320px;
  height: 40px;
}
.input-form.creator-form .create-button {
  font-family: "Segoe UI", wf_segoe-ui_normal, "Segoe WP", Tahoma, Arial, sans-serif;
  font-weight: 400;
  border-radius: 2px;
  margin-top: 30px;
  width: 320px;
  height: 40px;
}
.input-form.creator-form .create-button:not(.disabled) {
  background-color: #0078D7;
}
.input-form.creator-form .create-button:hover {
  background-color: #005aa1;
}
aui-document-editor {
  height: calc(100vh - 44px);
  z-index: 0;
}
aui-document-editor .document-editor {
  width: 350px;
  height: 100%;
}
aui-document-editor .document-editor.ng-hide-remove {
  animation: slide-in 0.15s ease-in;
}
aui-document-editor .document-editor.ng-hide-add {
  animation: slide-out 0.15s ease-in;
}
@keyframes slide-in {
  from {
    width: 0px;
    opacity: 0;
  }
  to {
    width: 350px;
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    width: 350px;
    opacity: 1;
  }
  to {
    width: 0px;
    opacity: 0;
  }
}
aui-document-editor .document-editor .tab-content {
  max-height: calc(100vh - 90px);
}
@keyframes animate-fade-in-scale {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.fade-in-opacity-scale {
  animation: animate-fade-in-scale 0.3s linear;
}
