@import (reference) "../../components/app-skeleton/app-skeleton.less";

kusto-manager-app {
    flex-grow: 1;

    .kusto-manager {
        height: 100%;

        .app-skeleton {
            .main-pane {
                .main-content {
                    .app-skeleton-tabs-row {
                        .app-skeleton-tab-specific-controls {
                            // Overriding default 30px, as the 10px would be added by the
                            // gap right to the arrow in the dropdown selector used by the
                            // table selector component
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;