@keyframes animate-fade-in-scale {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

.fade-in-opacity-scale {
    animation: animate-fade-in-scale 0.3s linear;
}
