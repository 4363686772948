@import (reference) "../../rta-cube-manager.less";

@kept-value-border-radius: 20px;
@kept-value-min-width: 40px;
@kept-value-max-width: 100px;

.rta-cube-manager-editor-capping-configuration {
    .table {
        tr {
            th {
                white-space: nowrap;
            }

            td {
                line-height: @app-skeleton-table-row-height;

                &.capped-values {
                    white-space: nowrap;
                }

                &.kept-values {
                    padding-right: @xl;
                }

                div {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
            }
        }
    }

    .value {
        .text-overflow;
        height: @md + @sm;
        line-height: @md + @sm;
        min-width: @kept-value-min-width;
        max-width: @kept-value-max-width;
        display: inline-block;
        padding: 0 10px;
        margin: 2px 3px 2px 0;
        background-color: @gray1;
        border-radius: @kept-value-border-radius;
    }
}

@enable-css-reset: false;