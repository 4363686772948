.app-skeleton-modal {
    &.clone-modal {
        .project-selector {
            max-width: inherit;

            .project-list {
                width: 100%;

                .aui-select {
                    .aui-select-content {
                        height: 250px;
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;