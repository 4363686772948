@import (reference) "../../../common/styles/aria-web";

.app-launcher {
    .scrollpane {
        position: relative;

        .scroll-padding-bottom;
    }

    .aui-list-item a {
        padding-left: 35px;
    }

    aui-accordion {
        .scroll-padding-bottom;
    }

    .aui-accordion {
        background: transparent;

        .panel-group .panel .panel-heading {
            background-color: @fluent-gray40;

            &:hover {
                background-color: @fluent-gray50;
            }

            .panel-title:hover {
                background: transparent;
            }
        }
    }

}

@enable-css-reset: false;