@import (reference) "~@ms-aria/app-fx/src/ui/styles/base";
@import (reference) "../../discard-rule-manager.less";

discard-rule-manager-editor {
    .flex-layout-column;
    overflow: hidden;

    .table {
        tbody {
            tr {
                td {
                    &.discard-rule-name {
                        input {
                            padding-left: @sm;
                        }
                    }
                }
            }
        }

        &.table-common {
            th:first-child {
                width: 30%;
            }
        }

        &.table-property-names {
            tbody {
                tr {
                    td {
                        &.property-name {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .error-message {
        color: @red;
        margin: @sm;
    }
}

@enable-css-reset: false;