@import (reference) "../../../../common/styles/aria-web";
@modal-height: 250px;

.aui-export-to-health-modal {
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    label {
        padding-left: @sm;
    }

    aui-text-field {
        padding-left: 5px;
    }

    .project-selector {
        max-width: inherit;

        .project-list {
            .aui-select {
                .aui-select-content {
                    height: 250px;
                }
            }
        }
    }

    .aui-dropdown-selector {
        display: inline-block;

        .aui-dropdown-menu .aui-dropdown-list {
            height: @modal-height;
        }

        .aui-dropdown-content {
            aui-spinner circle {
                stroke: black;
            }
        }
    }
}

@enable-css-reset: false;