@import (reference) "../../kusto-cube-manager.less";

@kusto-cube-manager-editor-pad-padding:               @app-skeleton-editor-pad-padding;
@kusto-cube-manager-editor-pad-plain-text-padding:    @app-skeleton-editor-pad-plain-text-padding;

kusto-cube-manager-editor {
    .flex-layout-column;
    overflow: hidden;

    .kusto-cube-manager-editor {
        ng-form.editor-form {
            fieldset {
                app-skeleton-editor-step {
                    &:nth-of-type(1) {
                        .app-skeleton-editor-step {
                            margin-top: 18px;
                        }
                    }

                    // Steps with query contents should extend their controls
                    // to full width. As we use flex here, it must be applied
                    // only in case of active and / or completed steps.
                    // Otherwise the default 'none' value would be overridden
                    // and step contents would be shown even in case of steps
                    // that have not been yet open
                    &:nth-of-type(2),
                    &:nth-of-type(6) {
                        .app-skeleton-editor-step {
                            &.active, &.completed {
                                .step-contents {
                                    display: flex;
                                    flex-wrap: wrap;

                                    .property-list {
                                        flex-grow: 1;
                                        flex-basis: 100%;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-of-type(5) {
                        .app-skeleton-editor-step {
                            .property-list {
                                // Make sure that timestamp dropdown overlays the dimensions table
                                position: relative;
                                z-index: @zIndex-1;

                                // Red colour on red warning icon next to the timestamp label
                                .icon-warning {
                                    color: @red;
                                }
                            }

                            // Limit the timestamp dropdown to display at most 7 items (more or less)
                            .aui-dropdown-list {
                                max-height: 20em;
                            }
                        }
                    }

                    &:nth-of-type(6) {
                        .app-skeleton-editor-step {
                            pre {
                                white-space: pre-wrap;
                            }

                            .aui-text-button {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                .step-contents {
                    .table {
                        tbody {
                            td {
                                &.property-name {
                                    .text-overflow-table-cell;
                                }

                                &.aggregation {
                                    > span {
                                        display: block;
                                        width: 100%;
                                        .text-overflow;
                                    }
                                }
                            }
                        }

                        // The list of rules below defines widths of columns.
                        // For clearness, do not extend those selectors with
                        // any other properties, but rather apply new rules
                        // somewhere else in this file
                        &.table-measures {
                            th:first-child {
                                width: 15%;
                            }

                            th:nth-child(2), td:nth-child(2) {
                                width: 15%;
                            }

                            th:nth-child(3) {
                                width: 15%;
                            }

                            th:nth-child(4) {
                                width: 15%;
                            }
                        }

                        &.table-dimensions {
                            th:first-child {
                                width: 30%;
                            }

                            th:nth-child(2) {
                                width: 30%;
                            }
                        }

                        // Non-column-width related customisations to tables
                    }

                    textarea {
                        resize: none;
                        font-family: Consolas;
                        // Must reduce width to account for left margin.
                        // Without that the textarea element is somehow pushed
                        // 15px too far to the right and slips out from the
                        // container
                        width: calc(~"100% - 15px");
                        background-color: @gray1;
                        padding: 20px 10px 20px 20px;
                        min-height: 60px;
                    }
                }
            }

            .bottom-bar {
                .restart {
                    padding-left: 15px;
                    color: @white;

                    button {
                        background-color: @green;
                    }
                }
            }
        }
    }
}

// Overridding setting from the app skeleton, as highest dropdown here is not
// present at the bottom of the page. Need to explicitly use long list of
// selectors to make sure that this one is more accurate than the one defined
// in the app skeleton's less file
.app-skeleton kusto-cube-manager-editor .kusto-cube-manager-editor ng-form.editor-form .scroll-container fieldset .spacer {
    padding-bottom: 150px;
}

@enable-css-reset: false;