@import (reference) "../project-manager.less";

.project-manager {
    .subscribers {
        position: relative;
        .scroll-padding-bottom;

        .filter-list {
            .aui-text-field {
                padding-left: 41px + @app-skeleton-table-left-padding;
            }
        }

        h3, h4 {
            padding-left: 41px + @app-skeleton-table-left-padding;
        }

        h3 {
            margin: 0;
            display: none;

            &.header-current {
                position: absolute;
                top: @app-skeleton-filtered-table-header-row-gap + @app-skeleton-filtered-table-filter-row-gap;
                left: 0;
            }
        }

        .filtered-table {
            thead {
                th {
                    &.project-name {
                        width: 15%;
                    }

                    &.project-owners {
                        width: 55%;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.add {
                            aui-text-button {
                                display: none;
                                text-align: right;

                                button {
                                    background-color: @blue;
                                }
                            }
                        }

                        &.project-name {
                            .text-overflow-table-cell;
                            width: 15%;
                        }

                        &.project-owners {
                            .text-overflow-table-cell;
                            width: 55%;
                        }
                    }

                    &:hover {
                        .add {
                            aui-text-button {
                                display: block;
                            }
                        }
                    }
                }
            }

            &.potential-subscribers {
                thead {
                    display: none;
                }
            }
        }

        &.filter-not-empty {
            h3 {
                display: block;
            }

            &:not(.read-only) {
                .filtered-table {
                    th {
                        // filter input + header for current subscribers +
                        // initial padding on the header
                        padding-bottom: @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-cell-padding;
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;