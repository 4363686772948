@import (reference) "../../../../common/styles/aria-web";

@card-width: 380px;
@card-height: 120px;

whats-new-card {
    width: @card-width;
    height: @card-height;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon-box {
        width: 60px;
        height: @card-height;
        padding: 20px 0;
        cursor: auto !important;

        i {
            color: @white;
            border-radius: 40px;
            font-size: 20px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: auto !important;
        }
    }

    .text-container {
        .aria-regular;
        width: 320px;
        height: @card-height;
        padding: 20px 0;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: left;

        .card-title {
            font-size: 13.5px;
            line-height: 1.6em;
            color: @gray7;
            margin-bottom: 8px;
        }

        .card-body {
            font-size: 12px;
            line-height: 1.6em;
            width: 297px;
            color: @gray6;
        }
    }
}

@enable-css-reset: false;