@import (reference) "../org-manager.less";

.org-manager {
    .projects {
        .table {
            td, th {
                &:first-child {
                    padding-left: @app-skeleton-table-left-padding;
                }
            }
        }
    }
}

@enable-css-reset: false;