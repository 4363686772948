@import (reference) "../../funnel-manager.less";
@import (reference) "../../../rta-cube-manager/rta-cube-manager.less";

@funnel-manager-editor-pad-padding:               @app-skeleton-editor-pad-padding;
@funnel-manager-step-no-circumference: 25px;

.app-skeleton funnel-manager-editor {
    .flex-layout-column;
    overflow: hidden;

    .funnels-help {
        line-height: @funnel-manager-step-no-circumference;
        margin-left: 10px;
        font-size: 18px;
        margin-top: 2px;
        color: @gray5;
    }

    .table {
        td {
            &.funnel-name {
                padding-left: 0;

                input {
                    padding-left: 8px;
                }
            }

            &.event-selector {
                .dropdown-menu {
                    a {
                        padding-left: @funnel-manager-editor-pad-padding;
                    }
                }
            }

            .event-name {
                padding-left: 0;
            }
        }

        // The list of rules below defines widths of columns.
        // For clearness, do not extend those selectors with
        // any other properties, but rather apply new rules
        // somewhere else in this file
        &.table-common {
            th:first-child {
                width: 30%;
            }
        }
    }

    .property-list > li .aui-dropdown-selector {
        .aui-dropdown {
            background-color: transparent;

            &:focus {
                background-color: transparent;
                border-bottom: solid 1px @blue;
            }
        }
    }
}

.funnel-property-filter-modal {
    display: flex;
    flex-direction: column;
    max-height: 80vh;

    aui-modal-body {
        max-height: 600px;
    }

    .rta-cube-manager-editor-property-selector {
        position: relative;

        aui-text-field {
            .dropdown-menu {
                .app-skeleton-typeahead-dropdown-mixin;

                flex-direction: row;
                width: 200%; // modal dropdown menu width is too tiny on small screens
            }

            input {
                border-bottom: 0;

                &:focus {
                    border-bottom: solid 1px @blue;
                }
            }
        }
    }

    .aui-text-field {
        padding-left: 0;
    }

    .aui-text-button {
        padding-left: 10px;
    }

    // The list of rules below defines widths of columns.
    // For clearness, do not extend those selectors with
    // any other properties, but rather apply new rules
    // somewhere else in this file
    .table > thead > tr {
        th:first-child {
            width: 30%;
        }

        th:nth-child(2) {
            width: 20%;
        }
    }

    .modal-dialog {
        min-width: 540px;
        width: 70%;
    }
}

// Overridding setting from the app skeleton, as highest dropdown here is not
// present at the bottom of the page. Need to explicitly use long list of
// selectors to make sure that this one is more accurate than the one defined
// in the app skeleton's less file
.app-skeleton funnel-manager-editor .funnel-manager-editor ng-form.editor-form .scroll-container fieldset .spacer {
    padding-bottom: 0;
}

@enable-css-reset: false;