@import (reference) "../../funnel-manager.less";


.funnel-manager-preview > .header-label {
    margin-left: 50px;
    margin-right: 30px;
    padding: 0;
    margin-top: 15px;
    text-transform: uppercase;
    color: inherit;
    display: inline-block;
    text-align: left;
}


.funnel-manager-preview {
    .frame-viz {
        height: calc(344px * 0.65);

        .aui-chrome {
            position: relative;
            border: none;
            box-shadow: none;
            background-color: transparent;

            .title {
                position: absolute;
                top: 0;
                right: 10px;
                z-index: 1;
                background-color: transparent;

                .viz-label {
                    display: none;
                }
            }

            .body {
                height: 100%;
            }
        }
    }
}

@enable-css-reset: false;