visualization {
    height: 100%;
    width: 100%;
}

viz-renderer-react {
    height: 100%;
    display: block;
}

.react-visualization {
    width: 100%;
    height: 100%;

    .visualization {
        width: 100%;
        height: 100%;
    }
}

@enable-css-reset: false;