@input-form-width: 320px;

.input-form.creator-form {
    flex-direction: column;
    width: 100%;

    h2 {
        margin: 0;
    }

    p {
        font-size: 16px;
        margin: 20px 0;
        max-width: 420px;
        text-align: center;
    }

    .image-wrapper {
        align-items: center;
        background-color: @gray1;
        border-radius: 50%;
        display: flex;
        height: 200px;
        width: 200px;
        justify-content: center;
        position: relative;
        margin-bottom: 30px;

        i {
            color: @gray5;
            font-size: 75px;
        }
    }

    label:not(.fui-Label) {
        width: @input-form-width;
        text-transform: uppercase;
        align-items: center;
        padding-left: 10px;
        margin-top: 10px;
    }

    .create-input {
        color: @gray7;
        width: @input-form-width;
        height: 40px;
    }

    .create-button {
        .aria-regular;
        border-radius: 2px;
        margin-top: 30px;
        width: @input-form-width;
        height: 40px;

        &:not(.disabled) {
            background-color: @blue;
        }

        &:hover {
            background-color: @blue-darken25;
        }
    }
}
