@import (reference) "../../../../common/styles/aria-web";
@selector-icon-width: 44px;
@selector-height: 44px;
@selector-max-width: 360px;
@selector-menu-width: 288px;
@filter-height: 40px;

multi-events-selector {
    .aria-regular;
    flex: 1;
    display: flex;
}

.multi-events-selector {
    flex: 1;
    display: flex;
    height: @selector-height;
    background: transparent;
    padding: 0;
    max-width: @selector-max-width;

    &:hover {
        cursor: pointer;
    }


    .event-selector-text {
        .text-overflow;
    }

    aui-spinner {
        display: inherit;
    }

    &.disabled:hover {
        cursor: default;

        i:hover {
            cursor: default;
        }
    }

    .event-selector-toggle {
        margin: 0;
        padding: 0 10px 0 15px;
        align-items: center;
        border: 0;
        background: transparent;
        overflow: hidden;

        .event-selector-toggle-content {
            height: 100%;
            display: flex;
            align-items: center;

            .icon-arrow-down {
                margin-left: 10px;
            }
        }

        &.pressed, &:hover, &:focus {
            .darken10;
        }
    }

    .funnel-events-list {
        // Make the dropdown to align to the width of the selector
        // container, but only if we are not appending to the body (hence
        // it is here, not in the clause below)
        width: 100%;
    }
}

.funnel-events-list {
    &.dropdown-menu {
        border: initial;
        margin: 0;
        padding: 0;
        max-height: 70vh;
        width: @selector-menu-width;
        font-size: inherit;
        border-radius: 0;
    }

    .aui-select {
        .aui-select-content {
            height: initial;
            max-height: 70vh;
        }
    }
}

@enable-css-reset: false;