@import (reference) "../../../common/styles/aria-web";

.app-skeleton.update-cube {
    .page-content {
        padding: 0;
    }

    ui-view {
        .flex-layout-column;
        overflow: hidden;

        rta-cube-manager-settings {
            .flex-layout-column;
            overflow: hidden;

            .rta-cube-manager-settings {
                .flex-layout-column;
                overflow: hidden;

                &.unsupported {
                    .resource-status-messages {
                        li {
                            padding-bottom: 0;

                            label {
                                margin: @stack-sm;
                                padding-bottom: 0;
                            }
                        }
                    }

                    .legacy-reasons {
                        margin: @stack-xs;
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;