@import (reference) "../../../../common/styles/aria-web";

.kusto-cube-manager-editor-display-query-results-modal {
    .aui-modal-body {
        // Making sure that in case if there are many columns, the content of
        // the modal does not expand itself outside of this modal edges
        overflow: hidden;

        .visualization {
            .dataTables_wrapper {
                .dataTable {
                    // Overriding default height set on the table. This
                    // fixes two issues: (1) the row with controls
                    // (prev/next, ..) is fully visible and (2) the modal
                    // does not increase its height with each key press /
                    // mouse scroll event (sic!)
                    height: auto;
                }
            }
        }

        .kusto-table-viewer-content {
            width: 100%;
            height: 60vh;
            border: 1px solid @gray2;
        }
    }
}

@enable-css-reset: false;