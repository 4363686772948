@import (reference) "../../../common/styles/aria-web";
@filter-height: 44px;
@selector-height: 600px;
@input-form-width: 320px;
@platform-dropdown-list-height: 32vh;

.input-form {
    min-height: ~"calc(100vh - 44px)";
    display: flex;
    align-items: center;
    justify-content: center;

    .setup-form {
        .form-submit {
            margin-top: 15px;
            background-color: @blue;
        }

        .link-container {
            display: block;
            margin-top: 15px;

            a {
                .aria-regular;
                color: @blue;
            }

            &.skip {
                margin-top: 20px;
            }
        }

        .aui-text-button {
            border: none;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .form-container {
            display: flex;
            justify-content: center;

            .form-group {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 0;
            }
        }

        .image-wrapper {
            align-items: center;
            background-color: @gray3;
            border-radius: 50%;
            display: flex;
            height: 200px;
            justify-content: center;
            margin: auto;
            position: relative;
            width: 200px;

            i {
                color: @gray5;
                font-size: 75px;
            }

            aui-spinner {
                position: absolute;
            }
        }

        h2 {
            margin-top: 30px;
        }

        p {
            font-size: 16px;
            margin: 0 0 10px 0;
            min-width: 320px;
            max-width: 440px;
            color: @gray6;
        }

        label {
            width: @input-form-width;
            text-align: left;
            padding-left: 10px;
            margin-top: 20px;
        }

        .create-project-input {
            width: @input-form-width;
            margin: 0;
            border-bottom: 1px solid @gray4;
        }

        .create-project-button {
            .aria-regular;
            background-color: @blue;
            margin-bottom: 20px;
        }

        .selector-container {
            display: flex;
            align-items: center;
            justify-content: center;

            &.service-tree-selector {
                flex-direction: column;
            }

            aui-dropdown-selector {
                width: @input-form-width;

                button {
                    width: @input-form-width;
                    border-bottom: 1px solid @gray4;
                    padding: 0 15px 0 10px;
                }

                .aui-dropdown-menu {
                    width: 100%;
                }

                .platform-selector-menu {
                    max-height: @platform-dropdown-list-height;

                    .aui-dropdown-list {
                        max-height: @platform-dropdown-list-height;
                    }
                }
            }

            .aui-dropdown-menu {
                text-align: center;
            }

            .selector-style {
                flex: none;
                margin-bottom: 20px;
                height: @filter-height;
                min-width: @input-form-width;
                text-align: left;
                border-bottom: 1px solid @gray4;
            }

            project-selector {
                .selector-style;

                button {
                    height: 100%;
                    width: 100%;
                }

                .project-selector-toggle-content {
                    justify-content: space-between;
                }
            }

            group-selector, service-tree-selector {
                .selector-style;
                margin-bottom: 0;

                .btn-group {
                    width: 100%;
                }

                .group-selector-toggle-content {
                    width: 100%;
                }

                button {
                    height: 100%;
                    width: 100%;
                    padding: 0;
                }
            }

            .service-tree-opt-out {
                width: @input-form-width;
                display: flex;
                flex-direction: column;
                text-align: left;

                & .service-tree-opt-out-checkbox {
                    width: @input-form-width;
                    display: flex;
                    align-items: center;
                }
                & label {
                    margin-top: 8px;
                }
            }
        }
    }
}

.input-form-project-selector {
    &.project-list {
        min-width: @input-form-width;
    }

    .aui-list {
        max-height: 200px;
    }
}

.input-form-group-selector {
    max-height: 500px;
}

.input-form-service-tree-selector {
    max-height: 500px;
}
