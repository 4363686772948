@import (reference) "../../rta-cube-manager.less";

.rta-cube-manager-cube-preview {
    margin-left: 28px;
    margin-right: 26px;

    aui-framed-viz {
        // Reducing chart height by 35%, as per request. The height of 344px
        // was empirically read from a living preview
        height: calc(344px * 0.65);

        .aui-chrome {
            position: relative;
            border: none;
            box-shadow: none;
            background-color: transparent;

            .title {
                position: absolute;
                top: 0;
                right: 10px;
                z-index: 1;
                background-color: transparent;

                .viz-label {
                    display: none;
                }
            }

            .body {
                height: 100%;
            }
        }
    }
}

@enable-css-reset: false;