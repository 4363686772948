@import (reference) "../../../common/styles/aria-web";

dashboard {
    .dashboard-flex-layout;

    flex-direction: column;

    .tab-view {
        .dashboard-flex-layout;
    }

    chart-versions-switch > div{
        background: #EEF2F8 !important;
    }

    .grafana-container {
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@enable-css-reset: false;