@import (reference) "../../../app-skeleton.less";

.app-skeleton-cube-or-filter-list {
    .table-property-filters {
        // Hide headers for all filter tables but the first one
        &:not(:first-child) {
            th {
                // Hide headers, not rows, so that the border at the bottom of
                // the row would be used as a separator between this table and
                // preceeding OR clause. The hidding technique is not by using
                // the display: property, as we could lose widths set for whole
                // table that are defined at the header level. Instead, we make
                // the header as small as possible
                padding-top: 0;
                padding-bottom: 0;
                font-size: 0;

                .ng-table-header {
                    min-height: 0;
                    line-height: 0;
                }
            }
        }
    }
}

// Very long to setup an override
.app-skeleton-modal .app-skeleton-cube-or-filter-list .property-name aui-text-field .dropdown-menu {
    width: @modal-width-m4 - (10 * @xs);
}

// Very long to setup an override
.app-skeleton .resource-manager-editor ng-form.editor-form .scroll-container fieldset .app-skeleton-cube-or-filter-list .property-name aui-text-field .dropdown-menu {
    // We want to make the dropdown as wide as the entire table, to be
    // consistent with other property selector dropdowns in this form. As it
    // initially takes full width of the cell, we need to do following
    // calculations: extend the dropdown proportionally to the full table width
    // (the cell gets 30% of the table) plus all paddings that have been added
    // between all cells
    width: calc(~"333% + 28px");
}

@enable-css-reset: false;