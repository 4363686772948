// Site-wide css goes here
@highlight-color: @red;

html, body {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    overflow-y: hidden;

    .fui-FluentProvider{
        background: transparent;
    }
}

iframe {
    border: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.content {
    margin: 0;
    display: flex;
    width: 100vw;
    height: 100vh;

    main {
        flex: 1;
        display: flex;
        position: relative;

        & div.viewport {
            position: relative;
            background-color: white;
            overflow: hidden;
            flex: 1;
            display: flex;

            &> div.viewport-root {
                position: relative;
                background-color: white;
                overflow: hidden;
                flex: 1;
                display: flex;
            }
        }

        &> .loading-page{
            position: absolute;
            width: 100%;
            height: 100%;
            background: @gray2;

            &> .spinner-container {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                &> p.header {
                    .aria-semi-bold;
                    text-align: center;
                    font-size: 21px;
                    color: @blue;
                }

                &> p.text {
                    font-size: 16px;
                    color: @gray6;
                }
            }
        }
    }
}

button {
    border-radius: 0;
}

.aui-text-button {
    &.link-like {
        color: @blue;
        width: 100%;
        text-align: left;
        padding: 0;

        &:hover {
            background: transparent;
        }

        &.danger {
            color: @red;
        }

        &.neutral {
            color: @gray6;
        }

        &.opportunity {
            color: @green;
        }
    }
}

// Override the aui-dropdown-menu z-index
.aui-dropdown-menu {
    z-index: @zIndex-dropdown;
}

@media (prefers-contrast: more) {
    .dropdown-menu > .active > a,
    .aui-dropdown-menu a:active,
    .aui-dropdown-menu a:focus,
    .aui-dropdown-selector a:active,
    .aui-dropdown-selector a:focus,
    .aui-list-item.highlight {
        outline: 2px solid black !important;
    }
}

// Override the default popover z-index
.aui-popover {
    z-index: @zIndex-popover;
}

// Override the aui-toolip z-index
.aui-tooltip {
    z-index: @zIndex-tooltip;
}

// mixin for hiding the scrollbar
.hide-scrollbar {
    div[class^="ps-scrollbar-"] {
        display: none;
    }
}

.app-titlebar aui-icon-button .aui-icon-button.highlight {
    background-color: @highlight-color;
}

.discard-modal .aui-text-button.commit {
    background-color: @red;
}

.zoom-view {
    z-index: @zIndex-viz-zoom;
    background-color: @white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: @standard-dim-width;

    &.zoomPinned {
        left: @standard-dim-width+@taskpane-width;
    }
}

.toggle-selector {
    &:focus {
        outline: none;
    }
}

@hue-preserving-invert-filter: invert(1) hue-rotate(180deg) brightness(1.1) contrast(0.9);
@hue-preserving-invert-filter-revert: invert(1) hue-rotate(-180deg) brightness(0.909091) contrast(1.111111);

.light-theme {
    *[data-begin-prefers-color-scheme="dark"] {
        filter: @hue-preserving-invert-filter;

        *[data-end-prefers-color-scheme="dark"] {
            filter: @hue-preserving-invert-filter-revert;
        }
    }
}

.dark-theme {
    *[data-begin-prefers-color-scheme="light"],
    :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
        filter: @hue-preserving-invert-filter-revert;

        *[data-end-prefers-color-scheme="light"] {
            filter: @hue-preserving-invert-filter;
        }
    }

    *:not([data-begin-prefers-color-scheme="light"]) {
        --dark-theme-box-shadow-color: #f8f8f8;
    }

    :is(.taskbar > ul > li:first-child, .sp-palette, .step-counter, .qr-container, img, video):not([data-begin-prefers-color-scheme="dark"]) {
        --dark-theme-box-shadow-color: #080808;
    }
}

@media (prefers-color-scheme: light) {
    // To remove feature flag, remove [data-prefers-color-scheme-feature-enabled="true"]
    body[data-prefers-color-scheme-feature-enabled="true"]:not([data-prefers-color-scheme="dark"]) {
        .light-theme()
    }
}

// To remove feature flag, remove [data-prefers-color-scheme-feature-enabled="true"]
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="light"] {
    .light-theme()
}

@media (prefers-color-scheme: dark) {
    // To remove feature flag, remove [data-prefers-color-scheme-feature-enabled="true"]
    // To make the system-dependent theme default instead of the light, replace [data-prefers-color-scheme="unset"] with :not([data-prefers-color-scheme="light"])
    body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="unset"] {
        filter: @hue-preserving-invert-filter;

        .dark-theme()
    }
}

// To remove feature flag, remove [data-prefers-color-scheme-feature-enabled="true"]
body[data-prefers-color-scheme-feature-enabled="true"][data-prefers-color-scheme="dark"] {
    filter: @hue-preserving-invert-filter;

    .dark-theme()
}
