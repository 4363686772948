@import (reference) "../../components/app-skeleton/app-skeleton.less";

.rta-cube-manager-warning-limits {
    strong {
        .aria-semi-light;
        font-weight: normal;
        font-style: italic;
    }
}

@enable-css-reset: false;