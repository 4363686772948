@import (reference) "../../../common/styles/aria-web";

event-explorer-schema {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .ee-schema {
        .flex-layout-column;
        position: relative;
        height: 100%;
    }

    .ee-event-metadata {
        .scroll-padding-bottom;
        flex: 1;

        .dataTables_wrapper {
            .dataTable {
                th, td {
                    .text-overflow;
                    max-width: 280px;

                    &:first-child {
                        padding-left: 30px;
                    }

                    &.dataTables_empty {
                        text-align: left;
                    }
                }

                td {
                    &:first-child {
                        color: @blue;
                    }
                }
            }

            .bottom {
                display: none;
            }

            .top {
                display: none;
            }
        }

    }

    .aui-text-field {
        border-bottom: none;
    }
}

@enable-css-reset: false;