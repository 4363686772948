@import (reference) "../../components/app-skeleton/app-skeleton.less";

kusto-cube-manager-app {
    flex-grow: 1;

    .kusto-cube-manager {
        height: 100%;
    }

    ui-view {
        .flex-layout-column;
        overflow: hidden;
    }
}

@enable-css-reset: false;