@import (reference) "../ingestion-manager.less";

.ingestion-manager {
    .keys {
        position: relative;
        .scroll-padding-bottom;

        h3 {
            margin: 0;
            display: none;

            &.header-results {
                position: absolute;
                // Headers row + filter row + header for add key row + add key row
                top: @app-skeleton-filtered-table-header-row-gap + @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-regular-row-gap;
                left: 0;
            }
        }

        .filter-list {
            .aui-text-field {
                padding-left: @app-skeleton-table-left-padding;
                background-color: @gray1;
            }
        }

        table {
            tr {
                th {
                    &:first-child {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 60%;
                    }
                }

                td {
                    &.key-name {
                        .text-overflow-table-cell;
                    }

                    &.token {
                        .text-overflow-table-cell;
                        padding: 0;

                        .copy-string {
                            color: inherit;
                            border: none;
                            height: @app-skeleton-table-row-height;

                            span {
                                padding-right: 15px;
                                line-height: 30px;
                            }

                            i {
                                color: @gray5;
                                line-height: 30px;
                                visibility: hidden;
                            }

                            &:hover {
                                color: @blue;

                                i {
                                    color: @blue;
                                }
                            }
                        }

                        .save-text {
                            padding-left: @app-skeleton-table-cell-padding;
                        }
                    }
                }

                &:hover {
                    td {
                        .copy-string {
                            i {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }

        &.filter-not-empty {
            h3 {
                display: block;
            }

            .filtered-table {
                th {
                    // filter input + header add key + add key row + header for
                    // authorised + initial padding on the header
                    padding-bottom: @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-regular-row-gap + @app-skeleton-table-section-header-height + @app-skeleton-table-cell-padding;
                }
            }
        }

        .add-keys {
            position: absolute;
            top: @app-skeleton-filtered-table-header-row-gap + @app-skeleton-filtered-table-filter-row-gap;
            left: 0;
            width: 100%;

            .add-keys-row {
                display: flex;
                line-height: @app-skeleton-table-row-height;
                border-bottom: solid 1px @gray1;

                // Style three elements below similarly to the columns in the
                // keys table, so that the widths match each other
                > span:nth-child(1) {
                    width: 25%;
                    padding-left: @app-skeleton-table-left-padding;
                }

                > span:nth-child(2) {
                    padding-left: @app-skeleton-table-cell-padding;
                    font-weight: bold;
                }

                aui-text-button {
                    flex-grow: 1;
                    text-align: right;
                    padding-right: @app-skeleton-table-right-padding;

                    button {
                        background-color: @blue;
                    }
                }

                &:hover {
                    background-color: @gray1;
                }
            }
        }
    }
}

@enable-css-reset: false;