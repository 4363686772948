@import (reference) "../../components/app-skeleton/app-skeleton.less";

discard-rule-manager-app {
    flex-grow: 1;

    .discard-rule-manager {
        height: 100%;
    }
}

@enable-css-reset: false;