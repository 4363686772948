@import (reference) "../../common/styles/aria-web";

@taskpane-header-height: 44px;
@taskbar-header-background-color: #1088E7;

taskpane {
    height: 100vh;

    aui-pull-up-menu {
        position: absolute;
        right: 25px;
        bottom: 25px;
        z-index: @zIndex-pull-up-menu;

        .aui-pull-up-menu .pull-up-item .pull-up-button {
            &.pull-up-create, &.pull-up-all {
                background-color: @blue;
            }

            &.pull-up-cube {
                background-color: @red;
            }

            &.pull-up-funnel {
                background-color: @purple;
            }

            &.pull-up-health-model {
                background-color: @yellow;
            }

            &.pull-up-user-role {
                background-color: @green;
            }

            &.pull-up-kusto {
                background-color: @orange;
            }

            &.pull-up-cosmos {
                background-color: @orange;
            }

            &.pull-up-event-hubs {
                background-color: @blue;
            }

            &.pull-up-notebook {
                background-color: @blue;
            }

            &.pull-up-dashboard {
                background-color: @green;
            }

            &.pull-up-visualization {
                background-color: @yellow;
            }

            &.pull-up-bookmark {
                background-color: @red;
            }

            &.pull-up-section {
                background-color: @orange;
            }

            &.pull-up-discard-rule {
                background-color: @red;
            }

            &.pull-up-more {
                background-color: @gray5;
            }
        }
    }
}

taskpane-header {
    .title-label {
        display: flex;
        flex: 1;
        align-self: center;
        justify-content: center;
        border-bottom: 1px solid @fluent-gray30;
    }
}

.taskpane {
    height: 100vh;
    background: @fluent-gray30;
    width: @taskpane-width;
    z-index: @zIndex-taskpane;
    position: absolute;

    .panel-group {
        width: @taskpane-width;
    }

    .backdrop-container {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        height: 50%;
        top: 25%;
        bottom: 25%;
        z-index: @zIndex-taskpane-backdrop-container;

        & > .backdrop-content {
            width: 100%;
            text-align: center;

            h2 {
                color: @fluent-gray180;
            }

            & > .description {
                padding: 0 50px;
                color: @fluent-gray180;
            }
        }
    }

    .alpha-mask {
        opacity: 0.5;
    }

    .taskpane-progress {
        position: absolute;
        z-index: @zIndex-loading;
        top: 44px;
        width: 100%;
        background: transparent;
        height: 2px;
        overflow: hidden;

        .moving-bar {
            height: 100%;
            animation: moving 1.5s infinite linear;
            background: @blue;
        }

        @keyframes moving {
            0% {
                transform: translateX(-90%) scaleX(0.6);
            }

            10% {
                transform: translateX(-70%) scaleX(0.45);
            }

            20% {
                transform: translateX(-60%) scaleX(0.4);
            }

            30% {
                transform: translateX(-40%) scaleX(0.3);
            }

            40% {
                transform: translateX(-20%) scaleX(0.15);
            }

            50% {
                transform: scaleX(0.1);
            }

            60% {
                transform: translateX(20%) scaleX(0.15);
            }

            70% {
                transform: translateX(40%) scaleX(0.3);
            }

            80% {
                transform: translateX(60%) scaleX(0.4);
            }

            90% {
                transform: translateX(70%) scaleX(0.45);
            }

            100% {
                transform: translateX(90%) scaleX(0.6);
            }
        }
    }

    &.ng-hide-remove {
        animation: slide-in .15s ease-in-out;
    }

    &.ng-hide-add {
        animation: slide-out .15s ease-in-out;
    }

    @keyframes slide-in {
        from {
            opacity: 0;
            left: -@taskpane-width;
        }

        to {
            opacity: 1;
            left: 0px;
        }
    }

    @keyframes slide-out {
        from {
            opacity: 1;
            left: 0px;
        }

        to {
            opacity: 0;
            left: -@taskpane-width;
        }
    }

    &.unpinned {
        box-shadow: 0 2px 16px var(--dark-theme-box-shadow-color, rgba(29,59,81,.16));
    }

    &.pinned {
        position: relative;
        box-shadow: none;
    }

    .taskpane-template {
        height: 100vh;
        position: relative;
        overflow: hidden;

        > * {
            position: absolute;
            height: 100vh;
            width: 100%;

            @slide-timing: .15s ease-in-out;

            &.slide-to-left {
                animation: slide-to-left @slide-timing;

                .hide-scrollbar;
            }

            &.slide-from-right {
                animation: slide-from-right @slide-timing;

                .hide-scrollbar;
            }

            &.slide-from-left {
                animation: slide-from-left @slide-timing;

                .hide-scrollbar;
            }

            &.slide-to-right {
                animation: slide-to-right @slide-timing;

                .hide-scrollbar;
            }

            @keyframes slide-to-left {
                from { transform: translateX(0); }

                to { transform: translateX(-@taskpane-width ); }
            }

            @keyframes slide-from-right {
                from { transform: translateX(@taskpane-width); }

                to { transform: translateX(0); }
            }

            @keyframes slide-from-left {
                from { transform: translateX(-@taskpane-width); }

                to { transform: translateX(0); }
            }

            @keyframes slide-to-right {
                from { transform: translateX(0); }

                to { transform: translateX(@taskpane-width); }
            }
        }
    }

    taskpane-content {
        height: 100vh;
        .flex-layout-column;
    }

    .taskpane-content {
        height: 100vh;
        .flex-layout-column;

        .taskpane-header {
            .text-body;
            color: @white;
            background: @taskbar-header-background-color;
            display: flex;
            height: @taskpane-header-height;
            line-height: @taskpane-header-height;

            .aui-popover-wrapper {
                padding-left: 5px;
            }

            .aui-icon-button:hover {
                background: @blue;
            }

            .aui-icon-button {
                height: @taskpane-header-height;
            }

            .aui-icon-button i {
                color: @white;
            }
        }

        taskpane-body {
            .flex-layout-column;

            .taskpane-body {
                display: flex;
                flex-direction: column;
                // Firefox and Edge aren't behaving properly with non-standard elements using display: flex;
                height: ~"calc(100vh - 44px)";


                .aui-list-item-content {
                    a {
                        display: flex;
                        padding-right: 39px;

                        .list-name {
                            flex: 1;
                            .text-overflow;
                        }

                        i {
                            .icon-1x;
                            align-self: center;
                            text-align: center;
                            width: 44px;
                            color: @fluent-gray180;
                        }
                    }
                }

                .inline-doc-editor .document-icon {
                    color: @fluent-gray180;
                }

                taskpane-search-filter {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .taskpane-pin {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: @fluent-gray180;

        &:hover {
            color: @blue;
        }
    }
}

@enable-css-reset: false;