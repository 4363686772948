@import (reference) "../../../common/styles/aria-web";

.visualization-zoom {
    .dashboard-flex-layout;
    flex-direction: column;
    height: 100%;

    .aui-text-button {
        height: 44px;
        display: flex;
        align-items: center;

        span:nth-child(2) {
            padding: 0 5px;
        }
    }

    .viz-page-content {
        .dashboard-flex-layout;

        aui-viz-renderer, viz-renderer-react, .viz-chart-content {
            flex: 1;
            min-width: 0px;
        }

        .viz-chart-content{
            display: flex;
            flex-direction: column;
        }
    }

    .viz-page-body {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    author-zoom-app-titlebar-content {
        flex: 1;
        height: inherit;
    }

    .right-action-menu-items {
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: flex-end;

        .autosave-title {
            display: flex;
            align-items: center;
        }

        .aui-icon-toolbar {
            flex: 0 0 auto;
        }
    }

    &.viz-zoom-dirty {
        .app-titlebar {
            .aui-icon-toolbar {
                .btn-icon-save:not(:hover) {
                    background-color: @red;
                }
            }
        }
    }
}
