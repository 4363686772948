@import (reference) "../../../common/styles/aria-web";

@tab-height: 42px;

user-settings {
    height: 100vh;

    .scrollpane {
        position: relative;
        flex: 1;
        padding-bottom: 100px;
    }

    .aui-tab-header {
        display: block;

        .tab-list .tab-list-item {
            flex: 1;
            padding: 0;
            height: @tab-height;

            .tab-select {
                flex: 1;
                max-width: initial;
                height: @tab-height;
                justify-content: center;
            }
        }
    }

    aui-list-heading {
        text-transform: uppercase;
        color: @fluent-gray180;
    }

    h2.theme-dark {
        color: @fluent-gray180;
    }

    .tab-view {
        flex: 1;
        display: none;

        &.showing {
            display: flex;
            flex-direction: column;
        }
    }

    aui-row-button {
        .row-wrapper  .label-text {
            padding-left: 44px;
        }

        button {
            border-top: 1px solid @fluent-gray30;

            &:focus, &:active {
                outline: none;
            }
        }
    }

    user-setting-selector {
        display: block;
        background-color: @fluent-gray20;
    }

    .aui-select .aui-select-content .aui-list-item:hover {
        background-color: @fluent-gray40;
    }

    aui-row-button button:hover {
        background: @fluent-gray40;
    }

    theme-control div:hover {
        background: @fluent-gray40;
    }

    .row-button-centered {
        height: 40px;
        width: 100%;
        border: 0;
        padding: 0;
        background: transparent;
        border-bottom: 1px solid @fluent-gray30;
        color: @light-blue;

        &:hover, &:focus {
            background: @fluent-gray40;
        }
    }
}

@enable-css-reset: false;