@import (reference) "../../../common/styles/aria-web";

.taskpane-global-search {
    .scrollpane {
        position: relative;

        .scroll-padding-bottom;
    }

    .aui-list-item {
        position: relative;

        .highlight {
            color: @blue;
        }
    }

    .boundary {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        text-align: right;
        font-size: 10px;
        border-top: 1px solid @fluent-gray30;
        background-color: inherit;
        right: 0;
        width: 100%;
        margin: 0;
        padding: 0 @xs 0;

        span {
            color: @fluent-gray80;
            background-color: inherit;
            line-height: @sm;
            height: @sm;
            padding-right: 8px;
            max-width: 250px;
            .text-overflow;
        }
    }
}


@enable-css-reset: false;