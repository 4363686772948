@import (reference) "../../common/styles/aria-web";

@data-app-sidebar-width: 350px;

.data-app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;

    app-titlebar {
        flex: 0 0 auto;

        .right-action-items {
            display: flex;
            flex-grow: 1;
            height: 100%;
            justify-content: flex-end;

            .icon-save.pristine {
                color: @blue-darken25;
            }

            .aui-icon-toolbar {
                flex-grow: 0;
            }
        }
    }

    .application {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        overflow: hidden;
        width: 100%;

        .main-pane {
            flex: 1 1 ~"calc(100% - @{data-app-sidebar-width})";
            min-width: ~"calc(100% - @{data-app-sidebar-width})";
            height: 100%;
        }

        .sidebar {
            max-width: @data-app-sidebar-width;
            flex: 0 0 @data-app-sidebar-width;
            background-color: @fluent-gray20;
            border-left: 1px solid @fluent-gray40;
            height: 100%;
            display: flex;
            overflow: hidden;

            aui-accordion .panel-group .panel .panel-heading {
                background-color: @fluent-gray30;
            }

            aui-accordion .aui-list-item:hover {
                background-color: @fluent-gray30;
            }
        }
    }
}

// Reflow accessibility rules:
// Vertical scrolling content at a width equivalent to 320 CSS pixels;
// Horizontal scrolling content at a height equivalent to 256 CSS pixels;
// I've set the max-height to 600 because it makes sense to adjust the styles
// from this stop already.
@media screen and (max-width: 800px) and (max-height: 600px){
    .data-app .application {
        flex-direction: column;
        overflow-y: scroll;

        .main-pane {
            width: 100%;
            height: auto;
        }

        .sidebar {
            width: 100%;
            max-height: 100px;
        }
    }
}
