@import (reference) "../../../common/styles/aria-web";

.new-user-tour {
    .code-generator{
        .link-container {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .aui-text-button {
            border-radius: 2px;
            margin: 15px 0;
        }

        a {
            .aria-regular;
        }

        h1 {
            padding-top: 30px;
            font-size: 28px;
        }

        p {
            width: 400px;
        }

        i {
            font-size: 20px;
            color: @gray5;
            padding-left: 10px;
        }

        .image-container {
            align-items: center;
            background-color: @gray1;
            border-radius: 100px;
            display: flex;
            height: 200px;
            width: 200px;
            justify-content: center;
            margin: auto;
            margin-top: 100px;
            position: relative;

            i {
                color: @gray5;
                cursor: default;
                font-size: 75px;
            }
        }

        .qr-container {
            align-items: center;
            background-color: @gray1;
            border-radius: 50%;
            display: flex;
            height: 280px;
            justify-content: center;
            margin: auto;
            margin-top: 80px;
            width: 280px;

            aui-spinner {
                z-index: 10;
            }
        }
    }
}

@enable-css-reset: false;