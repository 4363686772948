@import (reference) "../../components/app-skeleton/app-skeleton.less";

chart-explorer-app {
    flex-grow: 1;

    .chart-explorer {
        height: 100%;
    }
}

@enable-css-reset: false;