@import (reference) "../../common/styles/aria-web";

new-user-tour {
    flex-grow: 1;
}

.new-user-tour {
    background: @gray2;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;

    .app-titlebar {
        .name-container {
            flex: 1;
            margin-right: auto;
            text-align: left;
            white-space: nowrap;
        }

        .icon-breadcrumb-pipe {
            color: @blue-darken25;
            // Mocks sets 20px for the pipe which seems to work with 30px in
            // the glyph (the glyph have some paddings on top and bottom)
            font-size: 30px;
            margin: 0 -10px;
            cursor: default;
        }

        .step-counter {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;

            aui-icon-button {
                display: inline-block;
            }

            .circle {
                border-radius: 50%;
                display: inline-block;
                height: 10px;
                margin-right: 10px;
                width: 10px;
            }

            .empty-circle {
                .circle;
                .darken25;
            }

            .full-circle {
                .circle;
                background-color: @white;
            }
        }

        .welcome-link {
            color: @white;
            flex: 1;
            text-align: right;
            height: 100%;

            button {
                height: 44px;
                width: 172px;
            }
        }
    }

    .wizard {
        overflow: auto;
        padding-top: 50px;

        h1 {
            padding-top: 50px;
        }

        a {
            cursor: pointer;
        }

        p {
            font-size: 16px;
            color: @gray6;
        }

        .options {
            display: flex;
            justify-content: center;
            padding-bottom: 30px;
        }

        // Color classes for card tiles
        .red {
            background: @red;
        }

        .blue {
            background: @blue;
        }

        .yellow {
            background: @yellow;
        }

        .green {
            background: @green;
        }

        .orange {
            background: @orange;
        }

        .purple {
            background: @purple;
        }
    }
}

@enable-css-reset: false;