@import (reference) "../../../common/styles/aria-web";

@modal-width: 960px;
@modal-height: 620px;

external {
    width: @modal-width;
    height: @modal-height;
    background-image: url('../../../images/welcome.png');
    background-size: cover;

    .indicators {
        margin-top: 20px;

        ul {
            list-style: none;
            padding-left: 0;
        }

        li {
            width: 7px;
            height: 7px;
            display: inline-block;
            border-radius: 7px;
            background-color: #A5A5A5;
            margin-right: 8px;

            &.active {
                background-color: @white;
            }
        }
    }

    .welcome-slide {
        background-color: rgba(0, 120, 215, 0.9);
        width: @modal-width;
        height: @modal-height;
        color: @white;
        box-shadow: 0 13px 50px 0 var(--dark-theme-box-shadow-color, @gray6);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
            .text-giga;
            color: @white;
            margin: @stack-xl;
        }

        .start {
            .aria-regular;
            color: @blue;
            background-color: @white;
            border-radius: 2px;
            width: 207px;
            height: 50px;
            font-size: 16px;
            line-height: 21px;
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
            box-shadow: 0 1px 6px var(--dark-theme-box-shadow-color, @gray6);

            &:hover {
                -webkit-transform: translate(0px, -1px); /* Safari */
                transform: translate(0px, -1px);
                transition-duration: 0.2s;
                transition-timing-function: ease-in;
                box-shadow: 0 2px 20px var(--dark-theme-box-shadow-color, @gray6);
            }

            &:focus {
                outline: none;
            }
        }
    }

    .collect-signals-slide, .gain-insights-slide, .optimize-business-slide {
        width: @modal-width;
        height: @modal-height;
        background-color: @white;
        box-shadow: 0 13px 50px 0 var(--dark-theme-box-shadow-color, @gray6);

        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img-container {
            img {
                width: 480px;
                height: @modal-height;
            }
        }

        .text-container {
            width: 480px;
            height: @modal-height;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            svg {
                margin-top: 120px;
                margin-bottom: 20px;
                width: 40px;
                height: 40px;
            }

            .title {
                font-size: 37.5px;
                font-weight: 300px;
                color: @blue;
                line-height: 56px;
                margin: 22px 0;
            }

            .body {
                color: @gray6;
                font-size: 18.5px;
                font-weight: 300px;
                line-height: 29px;
                margin: 10px 0;
                width: 380px;
            }

            .next {
                .aria-regular;
                background-color: @blue;
                color: @white;
                margin: 35px 0;
                border-radius: 2px;
                width: 207px;
                height: 50px;
                font-size: 16px;
                line-height: 21px;
                transition-duration: 0.2s;
                transition-timing-function: ease-out;

                &:hover {
                    transition-duration: 0.2s;
                    transition-timing-function: ease-in;
                    background: @blue-darken25;
                }
            }

            .skip {
                .aria-regular;
                margin: 10px 0;
                background-color: transparent;
                color: @gray5;
                border-radius: 2px;
                width: 116px;
                height: 50px;
                font-size: 16px;
                line-height: 21px;
                transition-duration: 0.2s;
                transition-timing-function: ease-out;

                &:hover {
                    color: @blue;
                    transition-duration: 0.2s;
                    transition-timing-function: ease-in;
                }
            }
        }
    }

    .tos-slide {
        width: @modal-width;
        height: @modal-height;
        background-color: @white;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        padding-left: 90px;
        padding-right: 90px;

        .title {
            font-size: 37.5px;
            font-weight: 300;
            line-height: 60px;
            color: @blue;
            margin-top: 50px;
            height: 100px;
        }

        .body {
            p {
                font-size: 18.5px;
                font-weight: 300;
                line-height: 29px;
                margin-bottom: 26px;
                margin-top: 0;
            }

            ul {
                list-style-type: disc;
            }

            li, label {
                font-size: 13.5px;
                font-weight: 300;
                line-height: 21px;
                margin: 18px 0;
            }

            label {
                margin-left: 30px;

                input {
                    margin-right: 4px;
                }
            }
        }

        .button-container {
            .aria-regular;
            height: 120px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next, .skip {
                margin-left: 15px;
            }
        }
    }

    .admin-slide {
        width: @modal-width;
        height: @modal-height;
        background-color: @white;

        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        padding-left: 90px;
        padding-right: 90px;

        .title {
            font-size: 37.5px;
            font-weight: 300;
            line-height: 60px;
            color: @blue;
            margin-top: 50px;
            height: 100px;
        }

        .body {
            p {
                font-size: 18.5px;
                font-weight: 300;
                line-height: 29px;
                margin-bottom: 26px;
                margin-top: 0;
            }

            textarea {
                border: 1px solid @gray3;
                border-radius: 3px;
                background-color: @gray1;
                height: 270px;
            }
        }

        .button-container {
            height: 120px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next, .skip {
                margin-left: 15px;
            }
        }
    }
}

.external-windowClass {
    .modal-dialog {
        width: @modal-width;
        height: @modal-height;
    }

    .modal-content {
        border: 0;
    }
}

@enable-css-reset: false;