@import (reference) "../../../common/styles/aria-web";

@background-image-width: 44px;
@normal-icon-color: @fluent-gray180;
@active-icon-color: @fluent-black;
@normal-bg-color: @fluent-gray40;
@active-bg-color: @fluent-gray80;
@filter-height: 40px;

.taskpane-search-filter {
    display: flex;
    position: relative;
    background-color: @normal-bg-color;
    align-items: center;
    height: @filter-height;

    &.focused {
        .aui-icon-button {
            color: @active-icon-color;
        }
    }

    .filter-input {
        flex: 1;
        background-color: inherit;
        border: 0;
        height: @filter-height;
        width: 100%;
        color: @normal-icon-color;

        &:focus, &:active {
            outline: none;
            color: @active-icon-color;
        }
    }

    aui-icon-button {
        height: @filter-height;
        align-self: center;

        .aui-icon-button {
            color: @normal-icon-color;

            &:hover {
                background: @active-bg-color;
            }
        }
    }
}

@enable-css-reset: false;