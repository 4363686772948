@import (reference) "../../../common/styles/aria-web";
@input-form-width: 320px;


dashboard-frame {
    .dashboard-flex-layout;

    .dashboard {
        .dashboard-flex-layout;
        flex-direction: column;

        .flex-center {
            .dashboard-flex-layout;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .app-titlebar-left-section {
            display: flex;
            flex: 1;
            align-items: center;

            #app-titlebar-available-left-space {
                display: flex;
                flex: 1;
            }
        }

        .app-titlebar-right-section {
            display: flex;
            flex: 1;
            height: 100%;
            align-items: center;
            justify-content: flex-end;

            #app-titlebar-available-right-space {
                display: flex;
                flex: 1;
            }

            .autosave-title {
                display: flex;
                align-items: center;
            }

            .aui-icon-toolbar {
                height: 44px;
                flex: 0 0 auto;
            }
        }

        #dashboard-dimensions-bar {
            display: flex;
            padding-left: 16px;
            width: 100%;

            #dashboard-dimensions-selectors {
                display: flex;
            }
        }

        .dashboard-dimensions-bar-hidden {
            height: 0;
            overflow: hidden;
        }

        .page-wide-spinner {
            position: absolute;
            top: @tab-bar-height;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: @gray2;
            z-index: @zIndex-app-skeleton-page-spinner;

            aui-spinner {
                height: auto;
            }

            p {
                margin: 0;

                &.header {
                    .aria-semi-bold;
                    font-size: 21px;
                    color: @blue;
                    margin: 15px 0;
                }

                &.text {
                    font-size: 16px;
                    color: @gray6;
                }
            }
        }

        .locked-indicator{
            i {
                cursor: pointer;
            }
        }

        .dashboard-selector {
            .aui-dropdown-menu {
                min-width: 220px;
            }
        }

        .right-action-items {
            display: flex;
            flex: 1;
            height: 100%;
            align-items: center;
            justify-content: flex-end;

            .autosave-title {
                display: flex;
                align-items: center;
            }

            .aui-icon-toolbar {
                height: 44px;
                flex: 0 0 auto;
            }
        }

        &.doceditor-open {
            .app-titlebar {
                .aui-icon-toolbar {
                    .btn-icon-sidebar-right:not(:hover) {
                        background-color: darken(@blue, 50%, relative);
                    }
                }
            }
        }

        &.save-dirty {
            .app-titlebar {
                .dashboard-frame-toolbar {
                    .btn-icon-save:not(:hover) {
                        //TODO make this a mixin to align all dirty document states
                        background-color: @red;
                    }
                }
            }
        }

        .large-title {
            font-size: 3em;
            margin-bottom: 15px;
        }

        .page-container {
            .dashboard-flex-layout;

            &:has(.creator-form) {
                overflow-y: auto;
            }

            .page-content {
                .dashboard-flex-layout;
                flex-direction: column;

                .dashboard-view {
                    .dashboard-flex-layout;
                    cursor: auto;

                    dashboard {
                        .dashboard-flex-layout;
                    }
                }
            }

            .input-form {
                height: min-content;

                h2 {
                    margin: 0;
                }

                p {
                    font-size: 16px;
                    margin: 20px 0;
                    max-width: 420px;
                    text-align: center;
                }

                .image-wrapper {
                    align-items: center;
                    background-color: @gray1;
                    border-radius: 50%;
                    display: flex;
                    height: 200px;
                    width: 200px;
                    justify-content: center;
                    position: relative;
                    margin-bottom: 30px;

                    i {
                        color: @gray5;
                        font-size: 75px;
                    }
                }

                label:not(.fui-Label) {
                    width: @input-form-width;
                    text-transform: uppercase;
                    align-items: center;
                    padding-left: 10px;
                    margin-top: 30px;
                }

                dashboard-type-selector {
                    padding-left: 10px;
                    width: @input-form-width;

                    label {
                        text-transform: none;
                    }
                }
            }
        }
    }
}

@enable-css-reset: false;