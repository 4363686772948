@app-skeleton-resource-selector-dropdown-width: 375px;

.app-skeleton-resource-selector {
    aui-dropdown-selector {
        .dropdown-menu {
            max-width: @app-skeleton-resource-selector-dropdown-width;
        }
    }
}

@enable-css-reset: false;