@import (reference) "../../common/styles/aria-web";

@kusto-action-button-height: 34px;
@kusto-query-builder-height: 140px;

kusto-explorer-app {
    height: 100vh;
    width: 100vh;
    flex: 1;

    .data-app.is-dirty .aui-icon-toolbar {
        aui-icon-button[icon="icon-save"] {
            background-color: @red;
        }
    }

    .page-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tab-content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &.centered {
            justify-content: center;
            align-items: center;

            & aui-spinner {
                height: initial;
            }
        }
    }

    aui-kusto-query-builder {
        height: @kusto-query-builder-height;
    }

    .results-control-pane {
        background-color: @gray1;
        display: flex;
        align-items: center;
        padding-right: 15px;
        flex: 0 0 auto;
        gap: @md;

        aui-tab-header {
            flex: 1;
        }
    }

    aui-kusto-table-viewer {
        flex: 1;
        height: 100%;

        .kusto-table-viewer {
            flex: 1;
        }
    }

    aui-kusto-schema-explorer {
        height: 100%;
        width: 100%;
        display: flex;
        overflow: hidden;
    }

    @media screen and (max-width: 800px) and (max-height: 600px) {
        aui-kusto-table-viewer {
            .kusto-table-viewer-viewport {
                position: relative;

                .kusto-table-viewer-view {
                    width: auto !important;

                    .image-wrapper {
                        margin: 5px;
                        height: 100px;
                        width: 100px;
                    }

                    > h2 {
                        inline-size: 200px;
                        overflow-wrap: break-word;
                        text-align: center;
                    }
                }
            }
        }

        .results-control-pane {
            aui-tab-header {
                flex: 0;
            }

            aui-text-button {
                flex: 1;
            }
        }
    }
}

@enable-css-reset: false;