@import (reference) "../../../common/styles/aria-web";

.aui-event-properties-modal.event-metadata-details aui-modal-body {
    height: 50vh;
    padding: 0;
    overflow: auto;

    .aui-data-grid {
        height: initial;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        .scroll-padding-bottom;
    }

    .aui-visualization-base .dataTables_wrapper table.dataTable td, .aui-visualization-base .dataTables_wrapper table.dataTable th {
        max-width: initial;
    }

    tr {
        td:first-child {
            vertical-align: top;
        }
    }
}

@enable-css-reset: false;