@import (reference) "../../../common/styles/aria-web";
@import (once) "../../new-user-tour/input-form/input-form.less";

@health-band-red: #EC2100;
@health-band-yellow: #FFD200;
@health-band-green: #23C523;
@health-band-gray: @gray6;

@health-line-red: #F14124;
@health-line-yellow: #FFB900;
@health-line-green: #107C10;
@health-line-gray: @gray6;


health-app {
    width: 100%;

    .create-link {
        background-color: @gray2;
    }

    .hide-grain {
        aui-granularity-selector {
            display: none;
        }
    }

    // hide custom timeframe and 'all granularities for now
    aui-time-frame-selector, aui-granularity-selector {
        .aui-dropdown-selector .aui-dropdown-menu .aui-dropdown-list li:last-of-type {
            display: none;
        }
    }


    // TODO: add this back when we add tabs back in
    // allows for the negative margin on the doc editor
    // .app-skeleton .page-content.ps-container {
    //     overflow: initial !important;
    // }

    .health-tab {
        height: ~"calc(100vh - @{app-titlebar-height})";
        display: flex;
        // TODO: add this back when we have tabs back in
        // height: ~"calc(100vh - @{app-with-tabs-spacing})";
        // .author-tab-doc-editor {
        //     margin-top: -@tab-bar-height;
        // }
    }

    // TODO: remove this when we add tabs back in
    .app-skeleton .main-pane .main-content .app-skeleton-tabs-row {
        display: none;
    }

    aui-visualization, div.react-visualization {
        .c3-ygrid-line {
            text {
                fill: @gray6;
            }

            &.red line {
                stroke: @health-line-red;
            }

            &.yellow line {
                stroke: @health-line-yellow;
            }

            &.green line {
                stroke: @health-line-green;
            }

            &.gray line {
                stroke: @gray6;
            }
        }

        .c3-region {
            rect {
                // The in-line style generated by c3 always overwrites this to 0.1, so !important is needed
                fill-opacity: 0.3 !important;
            }

            &.red {
                fill: @health-band-red;
            }

            &.yellow {
                fill: @health-band-yellow;
            }

            &.green {
                fill: @health-band-green;
            }

            &.gray {
                fill: @gray6;
            }
        }

        .visualization {
            height: fit-content;
            width: 100%;
        }
    }

    health-monitor,
    health-analytics {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 110px;
        padding-top: 16px;
    }

    health-scorecard {
        flex-direction: column;

        h2 {
            margin-left: 28px;
        }
    }

    .health-app-table {
        display: flex;
        width: 100%;
        height: 100%;

        th.header:first-of-type {
            padding-left: 30px;
        }
    }
}

@enable-css-reset: false;