@import (reference) "./app-skeleton-mixins.less";

@app-skeleton-table-row-height: 44px;
@app-skeleton-table-left-padding: 30px;
@app-skeleton-table-right-padding: 30px;
@app-skeleton-table-heading-font-size: 12px;
@app-skeleton-table-heading-icon-font-size: 18px;
@app-skeleton-table-heading-icon-cell-width-first-child: 33px + @app-skeleton-table-left-padding;
@app-skeleton-table-cell-padding: 8px;
@app-skeleton-table-row-action-icon-width: 34px;
@app-skeleton-table-section-header-height: @app-skeleton-table-row-height + 10px;

@app-skeleton-padding-when-no-table: 0;
@app-skeleton-padding-when-no-dashboard: 30px;
@app-skeleton-padding-metrics-top: 15px;

@app-skeleton-sidebar-width: 350px;

@app-skeleton-table-regular-row-gap: @app-skeleton-table-row-height + 1px; // 1px for bottom border
@app-skeleton-filtered-table-header-row-gap: @app-skeleton-table-row-height;
@app-skeleton-filtered-table-filter-row-gap: @app-skeleton-table-row-height + 1px; // 1px for top border
@app-skeleton-filtered-table-text-field-padding: 10px;

@app-skeleton-label-font-size: 11px;

@app-skeleton-modal-option-height: 40px;
@app-skeleton-modal-gap-between-options: 15px;
@app-skeleton-default-modal-padding: 32px;
@app-skeleton-wider-modal-padding: 24px;
@app-skeleton-text-field-modal-padding: 11px;
@app-skeleton-wider-modal-padding-gap: @app-skeleton-default-modal-padding - @app-skeleton-wider-modal-padding;
@app-skeleton-modal-z-index-start-for-elements: 2000;

@app-skeleton-resource-name-height: 48px;
@app-skeleton-resource-name-width: 70%;
@app-skeleton-input-height: 40px;

@app-skeleton-tab-header-height: 45px;

@app-skeleton-property-control-width: 360px;

@app-skeleton-editor-pad-padding: 15px;
@app-skeleton-editor-pad-plain-text-padding: @app-skeleton-editor-pad-padding + 9px;
@app-skeleton-editor-pad-control-as-read-only-text: 10px;

.app-skeleton {
    .flex-layout-column;
    height: 100vh;
    overflow: hidden;

    .locked-indicator, .legacy-indicator {
        i {
            cursor: pointer;
        }
    }

    .right-action-items {
        display: flex;
        flex-grow: 1;
        height: 100%;
        justify-content: flex-end;

        .aui-icon-toolbar {
            flex-grow: 0;
        }
    }

    &.create-resource {
        ui-view {
            .flex-layout-column;
            overflow: hidden;

            .manager-creator {
                .flex-layout-column;
                overflow: hidden;
            }
        }
    }

    &.update-resource {
        .page-content {
            padding: 0;
        }

        ui-view {
            .flex-layout-column;
            overflow: hidden;

            .manager-settings {
                .flex-layout-column;
                overflow: hidden;
            }
        }
    }

    &.save-form {
        .app-titlebar {
            .aui-icon-toolbar {
                .btn-icon-save {
                    background-color: @red;
                }

                .btn-icon-warning {
                    background-color: @orange;
                }
            }
        }
    }

    &.sidebar-open {
        .app-titlebar {
            .aui-icon-toolbar {
                .btn-icon-sidebar-right:not(:hover) {
                    background-color: darken(@blue, 50%, relative);
                }
            }
        }
    }

    &.tabs-hidden {
        .main-pane {
            .page-wide-spinner {
                top: 0;
            }
        }
    }

    &.validation-errors {
        input, textarea {
            &.ng-invalid {
                border-bottom: 1px solid @red;

                &:not(:focus) {
                    &:not(:hover) {
                        border-bottom: 1px solid @red;
                    }
                }
            }
        }
    }

    .main-pane {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;

        .main-content {
            .flex-layout-column;
            overflow: hidden;

            .app-skeleton-tabs-row {
                display: flex;

                aui-tab-header {
                    flex-grow: 1;
                }

                .app-skeleton-tab-specific-controls {
                    display: flex;
                    align-items: center;
                    padding-right: @app-skeleton-padding-when-no-dashboard;
                    border-bottom: 1px solid @gray3;

                    .aui-text-button {
                        display: flex;
                        align-items: center;

                        i {
                            padding-right: 8px;
                        }
                    }
                }
            }

            .page-content {
                .flex-layout-column;
                position: relative;

                ui-view {
                    flex-grow: 1;

                    h1.resource-name {
                        .text-beta;
                        margin: 0;
                        height: @app-skeleton-resource-name-height;
                        line-height: @app-skeleton-resource-name-height;
                        width: @app-skeleton-resource-name-width;
                        .text-overflow;
                    }

                    p.resource-description {
                        .text-body;
                        margin: 0;
                        height: @app-skeleton-input-height;
                        line-height: @app-skeleton-input-height;
                        width: @app-skeleton-resource-name-width;
                        color: @gray6;
                        .text-overflow;
                    }
                }
            }
        }

        .sidebar {
            width: @app-skeleton-sidebar-width;
            background-color: @gray1;
            border-left: 1px solid @gray3;

            // Need to turn off flex on the accordion. Otherwise very wide
            // parts of the sidebar extend it beyond the view point (e.g. copy
            // string with an ingestion token)
            aui-accordion {
                display: block;
            }

            .usage {
                p {
                    margin-top: 0;

                    &:first-of-type {
                        margin-top: 1em;
                        margin-top: 1em;
                    }

                    &:last-of-type {
                        margin-bottom: 1.5em;
                    }
                }

                label {
                    text-transform: uppercase;
                }
            }

            .copy-string, .plain-text, .usage, .aui-dropdown-selector {
                margin-left: @property-editor-left-margin-narrow;
                margin-right: @property-editor-right-margin-narrow;

                &.copy-string {
                    margin-left: @property-editor-left-margin-narrow - 8px;
                }

                &.aui-dropdown-selector {
                    margin-left: @property-editor-left-margin-narrow - 8px;
                    border-bottom: 1px solid @gray3;
                    height: @app-skeleton-input-height;

                    .aui-dropdown {
                        padding: 6px 4px 6px 8px;
                    }
                }
            }
        }

        .page-wide-spinner, .page-wide-message {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: @gray2;
            z-index: @zIndex-app-skeleton-page-spinner;

            aui-spinner {
                height: auto;
            }

            p {
                max-width: 50%;
                margin: 0;

                &.header {
                    .aria-semi-bold;
                    font-size: 21px;
                    color: @blue;
                    margin: 15px 0;
                }

                &.text {
                    font-size: 16px;
                    color: @gray6;
                }
            }
        }
    }

    .property-list-group {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            h2 {
                margin: 0;
                padding: 10px 0 15px 0;
            }
        }
    }

    .property-list {
        width: 360px;
        margin: 0;
        padding: 0 0 15px 0;
        list-style: none;
        color: @gray6;
        transform: translateX(-15px);

        > li {
            padding-bottom: 10px;

            .label-with-right-actions {
                // Align everything to the right and the label to the left
                display: flex;
                justify-content: right;
                align-items: center;

                label {
                    flex-grow: 1;
                }

                .copy-string {
                    color: @blue;
                }

                :not(:first-child) {
                    padding-left: @sm;
                }
            }

            label {
                text-transform: uppercase;
                padding: 10px 0 5px 15px;

                i {
                    font-size: 18px;
                    color: @gray5;
                    display: inline-block;
                    height: 20px;
                }
            }

            textarea {
                margin-left: 15px;
            }

            .property {
                display: flex;
            }

            .aui-dropdown-selector {
                width: @app-skeleton-property-control-width;
                height: @app-skeleton-input-height;

                .aui-dropdown {
                    border-bottom: 1px solid @gray3;
                    flex-grow: 1;

                    .aui-dropdown-text {
                        flex-grow: 1;
                        text-align: left;
                    }
                }

                // Overridding max width in case if search was enabled, so that
                // hovering on an option higlights its full width
                a.search-enabled {
                    max-width: 100%;
                }
            }

            .disabled-input {
                width: @app-skeleton-property-control-width;
                display: block;
                color: @gray5;
                border-bottom: 1px solid @gray3;
                padding: 10px 15px 10px;
                height: @app-skeleton-input-height;
            }

            // Action button in property list in settings pages (usually
            // rendered as a button)
            .action-button {
                .aria-regular;
                line-height: @app-skeleton-input-height;
                color: @blue;
                margin-left: 16px;
            }

            // Verbatim text in property list in settings pages
            pre {
                margin: 0 0 0 15px;
                padding: 15px;
            }

            // Various messages
            .info-bar {
                margin: 0 0 0 15px;
            }
        }
    }

    .resource-details {
        display: block;
        padding-left: @app-skeleton-padding-when-no-dashboard;
        padding-top: @app-skeleton-padding-when-no-dashboard;
        padding-right: @app-skeleton-padding-when-no-dashboard;
        padding-bottom: @app-skeleton-padding-when-no-dashboard - @app-skeleton-padding-metrics-top;
    }

    .resource-status-messages {
        padding-left: @app-skeleton-padding-when-no-dashboard;
        padding-top: @app-skeleton-padding-metrics-top;
        padding-right: @app-skeleton-padding-when-no-dashboard;
        padding-bottom: 0;
        width: auto;
    }

    .metrics-charts {
        .dashboard-tab-content {
            background-color: @white;
        }
    }

    .resource-manager-editor {
        .flex-layout-column;
        overflow: hidden;

        ng-form.editor-form {
            .flex-layout-column;
            overflow: hidden;

            .scroll-container {
                flex-grow: 1;
                position: relative;

                fieldset {
                    padding: @app-skeleton-padding-when-no-dashboard @app-skeleton-padding-when-no-dashboard - 10px;

                    aui-text-field {
                        display: block;
                        width: 100%;
                        position: relative;

                        .dropdown-menu {
                            .app-skeleton-typeahead-dropdown-mixin;
                        }
                    }

                    .table {
                        // Needed to get ellipsis on long labels in dropdown toggle buttons
                        table-layout: fixed;

                        th, td {
                            &.action-icon {
                                &:last-child {
                                    width: @app-skeleton-table-row-action-icon-width + @app-skeleton-table-cell-padding;
                                }
                            }
                        }

                        thead {
                            th {
                                font-weight: normal;
                            }

                            label {
                                margin: 0;
                            }
                        }

                        tbody {
                            td {
                                .aui-text-field {
                                    background-color: transparent;

                                    &:hover {
                                        .darken10;
                                    }
                                }

                                &.resource-name {
                                    padding-left: 0;

                                    input {
                                        padding-left: 8px;
                                    }
                                }
                            }
                        }
                    }

                    .property-list {
                        padding-left: 9px;

                        .aui-text-field {
                            margin-left: 5px;
                        }
                    }

                    .button-table-row {
                        button {
                            padding-left: 9px;
                            color: @gray6;
                            border-bottom: 1px solid @gray3;
                        }
                    }

                    .input-table-row {
                        &, input {
                            height: @app-skeleton-table-row-height;

                            &:not(:focus) {
                                border-bottom: solid 1px @gray3;
                            }
                        }
                    }

                    .spacer {
                        // 300*1.25: so that users can scroll down in case of large dropdowns (300px is the height of the tallest dropdown)
                        padding-bottom: @app-skeleton-dropdown-max-height * 1.25;
                    }

                    fieldset {
                        // Clearing the padding set by the outer fieldset
                        // component
                        padding: 0;

                        // Settings disabled features on this fieldset and all
                        // descendants
                        &[disabled] {
                            .disabled;

                            * {
                                .disabled;
                            }
                        }
                    }
                }
            }

            .bottom-bar {
                border-top: 1px solid @gray1;
                padding: 0 15px 0 30px;
                // Set bar height to be at least height of a single button
                // (34px, no variable for that in the framework) plus 20px
                // paddings above and below. This is needed to address
                // different padding handling in flex containers on Chrome, as
                // on FF and Edge is enough just to write "padding: 20px 15px
                // 20px 30px" above
                min-height: 20px * 2 + 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .update {
                    color: @gray5;

                    button {
                        background-color: @gray2;
                    }

                    &:hover {
                        color: @white;
                    }
                }

                .toggle-advanced {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    justify-content: flex-end;

                    i {
                        padding-left: 10px;
                        font-size: 24px;
                        color: @blue;
                    }
                }
            }

            &.valid {
                .bottom-bar {
                    .update {
                        color: @white;

                        button:not(.disabled) {
                            background-color: @blue;
                        }
                    }
                }
            }
        }
    }

    aui-pull-up-menu {
        z-index: @zIndex-pull-up-menu;

        .aui-pull-up-menu {
            position: absolute;
            right: 20px;
            bottom: 20px;

            .pull-up-item .pull-up-button.pull-up-create {
                background-color: @blue;
            }
        }
    }
}

.app-skeleton, .app-skeleton-modal {
    .aui-text-field.resource-name,
    .action-edit td.resource-name .aui-text-field {
        .text-field-as-table-cell;
        .text-beta;
        width: @app-skeleton-resource-name-width;
        height: @app-skeleton-resource-name-height;
    }

    .aui-text-field.resource-description,
    .action-edit td.resource-description .aui-text-field {
        .text-field-as-table-cell;
        width: @app-skeleton-resource-name-width;
    }

    .modal-spinner {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        > aui-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    aui-dropdown-selector {
        .app-skeleton-dropdown-full-width-mixin;
    }

    .table {
        margin: 0;

        thead {
            tr {
                border-bottom: 1px solid @gray3;

                th {
                    .aria-semi-bold;
                    font-size: @app-skeleton-table-heading-font-size;
                    text-transform: uppercase;
                    text-align: left;
                    font-weight: normal;
                    border-bottom: none;
                    vertical-align: middle;
                    padding: 8px;

                    &.sort-asc, &.sort-desc {
                        background: none;
                    }

                    &.sortable {
                        .sort-indicator {
                            display: block;
                            .text-overflow;

                            &:before, &:after {
                                right: 0;
                            }

                            &:before {
                                margin-top: 1px;
                            }

                            &:after {
                                margin-top: -5px;
                            }
                        }
                    }

                    &.pad {
                        padding-left: @app-skeleton-editor-pad-plain-text-padding;
                    }

                    > .ng-table-header {
                        min-height: 28px;
                        line-height: 28px;
                    }

                    &:focus {
                        outline: none;
                        background-color: @gray1;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: solid 1px @gray3;

                td {
                    border: none;
                    padding: 0 @app-skeleton-table-cell-padding 0;
                    height: @app-skeleton-table-row-height;
                    vertical-align: middle;
                }

                .action-icon {
                    text-align: left;
                    padding: 0;

                    .aui-icon-button, .react-icon-button {
                        border: none;
                        background: none;
                        color: @gray6;
                        opacity: 0;
                        padding: 0;
                        height: @app-skeleton-table-row-height;

                        a {
                            color: inherit;
                        }

                        &:hover {
                            background-color: @gray2;
                            color: @blue;
                        }
                    }

                    .react-icon-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 42px;
                        height: 42px;

                        &:focus-visible {
                            outline: 1px solid @blue !important;
                        }
                    }

                    &.validation, &.warning {
                        font-size: 20px;
                        text-align: center;

                        i {
                            line-height: @app-skeleton-table-row-height;
                        }

                        &.validation {
                            color: @red;
                        }

                        &.warning {
                            color: @orange;
                            background-color: transparent;
                        }
                    }
                }

                .pad {
                    padding-left: @app-skeleton-editor-pad-padding;
                }

                .pad-plain-text {
                    padding-left: @app-skeleton-editor-pad-plain-text-padding;
                }

                .pad-read-only {
                    padding-left: @app-skeleton-editor-pad-control-as-read-only-text;
                }

                .toggle-selector {
                    padding: 0;

                    .data-row-value {
                        justify-content: flex-start;

                        i {
                            font-size: 28px;
                        }
                    }
                }

                &:hover, &:focus-within {
                    background-color: @gray1;
                    cursor: pointer;

                    td.action-icon {
                        .aui-icon-button, .react-icon-button {
                            opacity: unset;
                        }
                    }
                }
            }
        }

        td, th {
            &.action-icon {
                width: @app-skeleton-table-row-action-icon-width;

                .aui-icon-button {
                    width: @app-skeleton-table-row-action-icon-width;
                }

                &:last-child {
                    width: @app-skeleton-table-row-action-icon-width + @app-skeleton-table-right-padding;
                }
            }
        }

        &.filtered-table {
            thead {
                tr {
                    border-bottom: none;

                    th {
                        padding-bottom: @app-skeleton-filtered-table-filter-row-gap + @app-skeleton-table-cell-padding;
                    }
                }
            }

            td, th {
                &:first-child {
                    padding-left: @app-skeleton-table-left-padding;
                }

                &:last-child {
                    padding-right: @app-skeleton-table-right-padding;
                }
            }
        }

        &.first-column-icon {
            th, td {
                &:first-child {
                    font-size: @app-skeleton-table-heading-icon-font-size;
                    width: @app-skeleton-table-heading-icon-cell-width-first-child;
                }
            }
        }
    }

    h3.table-header, h4.table-no-results {
        height: @app-skeleton-table-row-height;
        line-height: @app-skeleton-table-row-height;
        margin: 0;
        padding: 0 @app-skeleton-table-left-padding 0;
        border-bottom: solid 1px @gray3;
        background-color: @white;
        width: 100%;

        &.table-header {
            height: @app-skeleton-table-section-header-height;
            padding-top: @app-skeleton-table-section-header-height - @app-skeleton-table-row-height;
        }

        &.table-no-results {
            .aria-font-size-standard;
        }
    }

    .filter-list {
        position: absolute;
        left: 0;
        top: @app-skeleton-filtered-table-header-row-gap;
        width: 100%;
        border-top: solid 1px @gray3;

        &.editing {
            &:before {
                background-color: @blue;
            }

            .filter-list {
                .icon-search {
                    color: @gray6;
                }
            }
        }

        .icon-search {
            position: absolute;
            left: @app-skeleton-table-left-padding;
            top: 0;
            line-height: @app-skeleton-table-row-height;
            font-size: @app-skeleton-table-heading-icon-font-size;
            color: @gray5;
        }

        .aui-text-field {
            height: @app-skeleton-table-row-height;
            line-height: @app-skeleton-table-row-height - (2 * @app-skeleton-filtered-table-text-field-padding);
            padding: @app-skeleton-filtered-table-text-field-padding;

            &:not(:focus) {
                border-bottom: solid 1px @gray3;
            }

            &:not(:hover) {
                // Needed in case if table headers have focus, as their changed
                // background can surface from bottom of this text control
                background-color: @gray1;
            }
        }
    }

    .button-table-row {
        width: 100%;
        display: inline-block;

        button {
            height: @app-skeleton-table-row-height;
            width: inherit;
            text-align: left;
            border-bottom: solid 1px @gray1;
            color: @gray5;
        }
    }

    .or-divider {
        text-align: center;
        min-width: 50px;
        height: 60px;
        line-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before, &:after {
            display: block;
            content: ' ';
            height: 1px;
            flex-grow: 1;
            background-color: @gray2;
        }

        &:before {
            margin-left: 80px;
            margin-right: 40px;
        }

        &:after {
            margin-left: 40px;
            margin-right: 80px;
        }
    }

    .info-bar {
        background-color: @gray1;
        border-left: 2px solid @blue;
        padding: 10px;

        &.info-warning {
            border-left-color: @yellow;
        }

        p {
            margin: 0;
        }
    }

    .aui-text-button {
        &.pointer-events-none {
            // Needed, if we want to handle clicks even in case of disabled
            // forms, which is a common technique to switch views to a
            // read-only mode. In such case one should use the on-click
            // handled, instead of an action attribute
            pointer-events: none;
        }
    }
}

.app-skeleton-modal {
    // .modal is required, as the .app-skeleton-modal is attached to the body.
    // Without .modal selector before we would target all controls, not only
    // those placed on modals
    .modal {
        .warning {
            color: @red;
            text-transform: uppercase;
            font-size: 80%;
            font-weight: bold;
        }

        label {
            text-transform: uppercase;
            font-size: @app-skeleton-label-font-size;
            font-weight: bold;
            margin-bottom: 0;
        }

        project-selector {
            max-width: none;

            .project-selector {
                .project-selector-toggle {
                    width: 100%;
                    padding-left: @app-skeleton-wider-modal-padding-gap;
                    padding-right: @app-skeleton-wider-modal-padding-gap;
                    height: @app-skeleton-modal-option-height;
                    border-bottom: 1px solid @gray4;

                    .project-selector-toggle-content {
                        .aria-semi-light;
                        justify-content: space-between;
                        // No project icon displayed, but also we do not want a
                        // spacer for it. Hence explicit padding 0
                        padding-left: 0;
                    }
                }
            }
        }

        .aui-text-field {
            padding: 0 @app-skeleton-text-field-modal-padding;
        }
    }

    &.full-body-available {
        aui-modal-body {
            padding: 0;

            .aui-modal-body {
                padding: 0;
            }
        }

        // Custom styles for the share modal
        &.share-modal {
            .aui-modal-body {
                padding: 30px @app-skeleton-wider-modal-padding;

                textarea {
                    .text-overflow;
                    height: @app-skeleton-input-height;
                }
            }
        }
    }

    // This class can be applied to modals that need a little bit more space
    // inside in order to accommodate some decorations around controls (like
    // wider bottom border in inputs)
    &.wider-modal-content {
        .aui-modal-body {
            label, p {
                padding-left: @app-skeleton-wider-modal-padding-gap;
            }

            label {
                margin-top: @app-skeleton-modal-gap-between-options;
            }

            .aui-text-field {
                padding-left: @app-skeleton-wider-modal-padding-gap;
                padding-right: @app-skeleton-wider-modal-padding-gap;
            }
        }
    }
}

// Reflow accessibility rules:
// Vertical scrolling content at a width equivalent to 320 CSS pixels;
// Horizontal scrolling content at a height equivalent to 256 CSS pixels;
@media screen and (max-width: 320px) {
    .app-skeleton .main-pane {
        flex-direction: column;
        overflow-y: scroll;

        .main-content {
            order: 1;
            flex: 1;
            overflow-y: scroll;
        }

        .sidebar {
            order: 2;
            flex: 1;
            overflow-y: scroll;

            .usage {
                margin: 0;
                margin-left: 0;
                margin-right: 0;
                max-width: 267px;
            }
        }
    }
}
