@import (reference) "../../common/styles/aria-web";

@page-background-color: #F8F8F8;
@box-width: 600px;

register {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: @page-background-color;

    app-titlebar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    .register-container {
        width: @box-width;
        background-color: @white;
        padding: 0 20px 20px 20px;
        line-height: 21px;

        .register-text {
            margin: 12px 0;
        }

        .agree-text {
            margin-top: 20px;
            color: @gray7;
        }

        .agree-btn {
            margin-top: 20px;
        }
    }

    p {
        margin: 0;

        &.header {
            color: @blue;
            font-size: 37.5px;
        }

        &.text {
            font-size: 18.5px;
        }
    }
}

@enable-css-reset: false;