@import (reference) "../kusto-manager.less";

.app-skeleton.kusto-tables {
    ui-view, kusto-manager-tables, .kusto-manager-tables {
        .flex-layout-column;
        overflow: hidden;
    }
}

.kusto-manager-tables {
    padding: 0;

    .tables-table {
        flex-grow: 1;
        position: relative;
        .scroll-padding-bottom;
    }

    h3 {
        margin: 0;
        display: none;

        &.header-results {
            position: absolute;
            top: @app-skeleton-filtered-table-header-row-gap + (3 * @app-skeleton-filtered-table-filter-row-gap);
            left: 0;
        }
    }

    .filter-list {
        .aui-text-field {
            padding-left: @app-skeleton-table-left-padding;
        }
    }

    table {
        table-layout: fixed;
        min-width: 800px;

        tr {
            td {
                &.column-name {
                    .text-overflow-table-cell;
                }

                &.column-compression {
                    i {
                        display: inline-block;
                        width: 1.2em;

                        &.icon-status-red {
                            color: @red;
                        }

                        &.icon-status-yellow {
                            color: @yellow;
                        }

                        &.icon-status-green {
                            color: @green;
                        }
                    }
                }
            }

            // The list of rules below defines widths of columns.
            // For clearness, do not extend those selectors with
            // any other properties, but rather apply new rules
            // somewhere else in this file
            th {
                &:first-child {
                    width: 30%;
                }
            }

            td, th {
                &.column-compression,
                &.column-space-used,
                &.column-percent-space-used {
                    text-align: right;
                }
            }
        }
    }
}

@enable-css-reset: false;