@import (reference) "../../../common/styles/aria-web";

.explorer {
    height: 100vh;
    .flex-layout-column;

    .taskpane-header-label.back {
        background: transparent;
        border: 0;
    }

    .taskpane-header {
        display: flex;

        & > .taskpane-header-label {
            .aria-regular;
            .text-overflow;
            flex: 1;
            text-align: center;
        }
    }

    .scrollpane {
        position: relative;
        flex: 1;
    }
}

@enable-css-reset: false;