@import (reference) "../../components/app-skeleton/app-skeleton.less";

cosmos-manager-app {
    flex-grow: 1;

    .cosmos-manager {
        height: 100%;
    }
}

@enable-css-reset: false;