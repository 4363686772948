@import (reference) "../../../common/styles/aria-web";
@import (reference) "~@ms-aria/app-fx/src/ui/editors/controls/selectors-base";

.dashboard-clone-modal {
    .aui-modal-body {
        form {
            display: flex;
            flex-direction: column;
            gap: @lg;
        }
        label {
            padding-left: @sm;
            margin-bottom: 0;
        }
    }

    .aui-modal-footer {
        .clone-button {
            aui-spinner {
                color: @white;
            }

            .clone-text {
                width: 100%;
            }
        }
    }
}


@enable-css-reset: false;