@import (reference) "../kusto-cube-manager.less";

kusto-cube-manager-logs {
    flex-grow: 1;
    height: 100%;

    .kusto-cube-manager-logs {
        height: 100%;
    }
}

@enable-css-reset: false;