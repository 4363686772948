@import (reference) "../../components/app-skeleton/app-skeleton.less";

event-hubs-manager-app {
    flex-grow: 1;

    .is-dirty .aui-icon-toolbar .btn-icon-save{
        background-color: red !important;
    }

    .event-hubs-wrapper{
        height: 100%;
        width: 100%;
        max-width: calc(100vw - 44px);
        display: flex;
    }

    .event-hubs-manager {
        height: 100%;
        padding-bottom: 100px; // needed to obtain the satisfying height of the component, so scrolling does not cut the create button
        display: block;
        overflow-y: auto;
        flex: 1;
    }

    .sidebar {
        width: @app-skeleton-sidebar-width;
        height: 100%;
        background-color: @gray1;
        border-left: 1px solid @gray3;
    }

    .padding-added {
        padding: 32px;
    }
}

@enable-css-reset: false;