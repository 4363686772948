@import (reference) "../../../common/styles/aria-web";

@modal-width: 960px;
@modal-height: 620px;

internal {
    width: @modal-width;
    height: @modal-height;
    background-image: url('../../../images/welcome.png');
    background-size: cover;

    .indicators {
        margin-top: 20px;

        ul {
            list-style: none;
            padding-left: 0;
        }

        li {
            width: 7px;
            height: 7px;
            display: inline-block;
            border-radius: 7px;
            background-color: #A5A5A5;
            margin-right: 8px;

            &.active {
                background-color: @white;
            }
        }
    }

    .welcome-slide {
        background-color: rgba(0, 120, 215, 0.9);
        width: 100vw;
        max-width: @modal-width;
        height: 100vh;
        max-height: @modal-height;
        color: @white;
        box-shadow: 0 13px 50px 0 var(--dark-theme-box-shadow-color, @gray6);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
            .text-giga;
            color: @white;
            margin: @stack-lg;
            text-align: center;

            @media screen and (max-width: 600px) {
                .text-mega;
            }
        }

        .start {
            .aria-regular;
            color: @blue;
            background-color: @white;
            border-radius: 2px;
            width: 207px;
            height: 50px;
            font-size: 16px;
            line-height: 21px;
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
            box-shadow: 0 1px 6px var(--dark-theme-box-shadow-color, @gray6);

            &:hover {
                -webkit-transform: translate(0px, -1px); /* Safari */
                transform: translate(0px, -1px);
                transition-duration: 0.2s;
                transition-timing-function: ease-in;
                box-shadow: 0 2px 20px var(--dark-theme-box-shadow-color, @gray6);
            }
        }
    }

    .new-features-slide, .usability-slide, .dev-ex-slide {
        background-color: @white;
        width: 100vw;
        max-width: @modal-width;
        height: 100vh;
        max-height: @modal-height;
        overflow: auto;
        box-shadow: 0 13px 50px 0 var(--dark-theme-box-shadow-color, @gray6);

        padding-left: 90px;
        padding-right: 90px;

        @media screen and (max-width: 600px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .title {
            .text-alpha;
            min-height: 130px;
            color: @blue;
            padding-top: 50px;

            font-size: 37.5px;
            line-height: 60px;
        }

        .cards-container {
            height: 370px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            overflow: auto;
        }

        .button-container {
            .aria-regular;
            height: 120px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .skip {
                background-color: @gray3;
                color: @gray7;
            }

            .next {
                background-color: @blue;
                color: @white;
                margin-left: 15px;
            }
        }
    }
}

.internal-windowClass {
    .modal-dialog {
        width: 100vw;
        max-width: @modal-width;
        height: 100vh;
        max-height: @modal-height;
    }

    .modal-content {
        border: 0;
    }
}

@enable-css-reset: false;