@import (reference) "../../components/app-skeleton/app-skeleton.less";

record-annotation-rule-app {
    flex-grow: 1;

    record-annotation-rule-summarise {
        height: 100%;
        display: block;
        padding: 15px;
    }

    record-annotation-rule-generate {
        height: 100%;
        display: block;
        padding: 15px;
    }
}

@enable-css-reset: false;