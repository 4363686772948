@import (reference) "../../../common/styles/aria-web";

.documentation-container {
    .documentation {
        box-shadow: 0 13px 50px 0 var(--dark-theme-box-shadow-color, rgba(22, 34, 58, 0.2));
        min-height: 40vh;
        width: 800px;
        margin: 30px 0;
        background-color: @white;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 15px;

        pre {
            width: 710px;
            padding: 20px;
            border: none;
            border-radius: 2px;
            background-color: @gray1;
            font-size: 14px;

            &:hover {
                color: @blue;
            }
        }

        iframe {
            width: 100%;
            margin-top: 20px;
        }

        .copy-container {
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-left: 30px;

            &:nth-of-type(2) {
                margin-right: 30px;
            }
        }

        .aui-spinner {
            margin-bottom: 10px;
        }

        .link-container {
            display: block;

            a {
                .aria-semi-light;
                color: @blue;
            }
        }
    }

    .next-button {
        .aria-regular;
        background-color: @blue;
        float: right;
        margin-bottom: 30px;
    }
}

@enable-css-reset: false;