aui-document-editor {
    height: ~"calc(100vh - @{standard-dim-height})";
    z-index: 0;

    .document-editor {
        width: @document-editor-width;
        height: 100%;

        &.ng-hide-remove {
            animation: slide-in .15s ease-in;
        }

        &.ng-hide-add {
            animation: slide-out .15s ease-in;
        }

        @keyframes slide-in {
            from { width: 0px; opacity: 0; }

            to {
                width: @document-editor-width;
                opacity: 1;
            }
        }

        @keyframes slide-out {
            from {
                width: @document-editor-width;
                opacity: 1;
            }

            to { width: 0px; opacity: 0; }
        }

        .tab-content {
            max-height: ~"calc(100vh - @{app-with-tabs-spacing})";
        }
    }
}
