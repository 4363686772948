@import (reference) "../../../../../common/styles/aria-web";
@heading-height: 35px;

notebook-view {
    height: 100vh;

    .aui-list-heading {
        height: @heading-height;
    }

    .aui-list {
        width: 100%;

        &.sections-list {
            padding: 0;
        }

        .document-icon:hover {
            cursor: move;
        }

        &.dnd-disabled .document-icon:hover {
            cursor: initial;
        }

        aui-inline-document-editor .inline-doc-editor {
            &:hover {
                cursor: move;
            }
        }

        .dndPlaceholder {
            height: 2px;
            background-color: @light-blue;
            display: block;
        }
    }

    .section {
        .page > .inline-doc-editor {
            padding-left: 30px;
        }
    }

    .inline-doc-editor {
        border: 0;
    }

    .notebook-view-contents {
        height: 100%;
        position: relative;
        .scroll-padding-bottom;
    }
}

@enable-css-reset: false;