@import (reference) "~@ms-aria/app-fx/src/ui/styles/base";
@viz-modal-project-selector-width: 478px;

.visualization-save-modal {
    .aui-modal-body {
        min-height: 250px;

        .label-text {
            display: inline-block;
            padding-left: @sm;
            padding-bottom: @xs;
            color: inherit;
        }

        .aui-dropdown-selector {
            margin-bottom: @md + @sm;
        }

        .aui-dropdown {
            width: 100%;
            padding: 0 @sm;
            border-bottom: 1px solid @gray3;
        }

        .dropdown-menu {
            width: 100%;

            .aui-dropdown-list {
                max-width: 100%;
            }
        }

        .project-selector {
            max-width: unset;
            margin-bottom: @md + @sm;
            border-bottom: 1px solid @gray3;

            .project-selector-toggle {
                width: 100%;
                padding: 0 @sm+@xs 0 @sm;

                .icon-arrow-down {
                    margin-left: auto;
                }
            }
        }

        .notebook-section {
            .aui-text-field {
                margin-bottom: @md + @sm;
            }
        }
    }
}

.viz-create-modal {
    &.project-list.dropdown-menu {
        min-width: @viz-modal-project-selector-width;
    }
}

@enable-css-reset: false;