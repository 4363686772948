@import (reference) "../../common/styles/aria-web";

@taskbar-item-width: @standard-dim-width;
@taskbar-item-height: @standard-dim-height;

@shadow-color: rgba(0, 0, 0, 0.2);
@taskbar-background-color: @fluent-gray30;

taskbar {
    .aria-regular;

    background-color: @taskbar-background-color;
    box-shadow: 0 0 0.75em 0.05em var(--dark-theme-box-shadow-color, @shadow-color);
    width: @taskbar-item-width;
    height: 100vh;
    z-index: @zIndex-taskbar;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.taskbar-item {
    display: flex;
    height: @taskbar-item-height;
    width: @taskbar-item-width;
    cursor: pointer;
    background-color: @taskbar-background-color;

    &.task-bar-item-eu {
        position: relative;
    }

    .eu-text {
        position: absolute;
        top: 24px;
        left: 24px;
        color: #FFCC00;
    }

    button {
        margin: auto;
        text-align: center;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 18px;
            width: 100%;
            color: @fluent-gray180;
        }
    }

    .aria-home {
        flex: 1;
        height: @taskbar-item-height;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgICB2ZXJzaW9uPSIxLjAiICAgdmlld0JveD0iMCAwIDk2IDk2IiAgIGlkPSJzdmczMDE0Ij4gIDxtZXRhZGF0YSAgICAgaWQ9Im1ldGFkYXRhMTEiPiAgICA8cmRmOlJERj4gICAgICA8Y2M6V29yayAgICAgICAgIHJkZjphYm91dD0iIj4gICAgICAgIDxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0PiAgICAgICAgPGRjOnR5cGUgICAgICAgICAgIHJkZjpyZXNvdXJjZT0iaHR0cDovL3B1cmwub3JnL2RjL2RjbWl0eXBlL1N0aWxsSW1hZ2UiIC8+ICAgICAgICA8ZGM6dGl0bGU+PC9kYzp0aXRsZT4gICAgICA8L2NjOldvcms+ICAgIDwvcmRmOlJERj4gIDwvbWV0YWRhdGE+ICA8ZGVmcyAgICAgaWQ9ImRlZnM5IiAvPiAgPHBhdGggICAgIGZpbGw9Im5vbmUiICAgICBzdHJva2Utd2lkdGg9IjFweCIgICAgIHN0cm9rZT0iIzAwMCIgICAgIGQ9Im0wLTI2OC4zMiIgICAgIGlkPSJwYXRoMzAzOCIgLz4gIDxnICAgICB0cmFuc2Zvcm09Im1hdHJpeCgwLjgxMjUwNDE4LDAsMCwwLjgxMjUwNDE4LDkuMDAwMDA0OSw4Ljk5OTMzNzcpIiAgICAgaWQ9ImcyOTg3Ij4gICAgPHBhdGggICAgICAgc3R5bGU9ImZpbGw6I2ZmZmZmZiIgICAgICAgZD0ibSA1OS4zOSw0OS40OTcgMTQuMTk2LDI2LjA1NSAtMjcuMzk2LDAgLTEzLjIsMCAtNy4yMjI1LDEzLjMzOSAyMC40MjIsMCA0OS44MSwwIC0yNC45MDUsLTQ1Ljc1MiAtMTAuMDg3LC0xOC41NzUgLTcuNTk2LDEzLjgzOCB6IiAgICAgICBpZD0icGF0aDMwNDYiIC8+ICAgIDxwYXRoICAgICAgIHN0eWxlPSJmaWxsOiMyOWFiZTIiICAgICAgIGQ9Im0gNi41OTA3LDkwLjI2MiBjIC0wLjk5NjIsMCAtMi4yNDE1LC0wLjM3Mzk5IC0zLjIzNzcsLTAuODcyNjMgLTMuMTEzMSwtMS44NyAtNC4yMzM5LC01Ljg1OSAtMi42MTUsLTguOTc2IDM4Ljg1MiwtNzEuMTgyIDM4Ljg1MiwtNzEuMTgyIDM4Ljg1MiwtNzEuMTgyIDEuODY4LC0zLjI0MTIgNS44NTMsLTQuNDg3OCA5LjA5LC0yLjYxNzkgMy4yMzgsMS42MjA2IDQuMzU5LDUuODU5MSAyLjc0LDguOTc2MSAtMzkuMTAxLDcxLjE4MiAtMzkuMTAxLDcxLjE4MiAtMzkuMTAxLDcxLjE4MiAtMS4xMjA3LDIuMTE5MyAtMy4yMzc3LDMuNDkwNiAtNS43MjgyLDMuNDkwNiIgICAgICAgaWQ9InBhdGgzMDQ4IiAvPiAgPC9nPjwvc3ZnPg==);
        background-color: @fluent-cyan-blue20;

        &:hover {
            background-color: @fluent-cyan-blue80;
        }
    }

    .react-icon-button {
        border-radius: 0 !important;
    }

    &.active {
        i {
            color: @fluent-gray180;
        }

        .react-icon-button {
            box-shadow: inset 7px 0 0px -4px var(--dark-theme-box-shadow-color, @blue);

            i {
                color: @fluent-gray180;
            }
        }
    }

    &:hover {
        background-color: @fluent-gray40;

        i {
            color: @fluent-gray180;
        }
;
        .react-icon-button,.react-icon-button:hover,.react-icon-button:hover:active {
            background-color: @fluent-gray40 !important;
            i {
                color: @fluent-gray180 !important;
            }
        }
    }
}

@enable-css-reset: false;