@import (reference) "../../../common/styles/aria-web";

@add-visualization-distance-to-edge: 20px;

dashboard-tab {
    display: flex;
    flex: 1;
    position: relative;

    .add-visualization {
        position: absolute;
        bottom: @add-visualization-distance-to-edge;
        right: 0;
        z-index: @zIndex-dashboard-tab-add-viz;

        .spinner-container {
            height: 55px;
            width: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: @add-visualization-distance-to-edge;
        }

        .aui-pull-up-menu {
            padding-right: @add-visualization-distance-to-edge;
        }
    }

    .dashboard-tab-content {
        max-height: ~"calc(100vh - @{app-with-tabs-spacing})";
        position: relative;
        flex: 1;
        background-color: @gray2;

        .create-viz-loading {
            position: relative;
            top: 50%;
            transform: translateY(-50%)
        }

        .viz-container {
            margin-bottom: 100px;

            .gridster-item {
                outline: none;
                z-index: auto;
                background-color: @white;
            }

            .gridster-item-moving {
                z-index: 2;

                .aui-chrome .body {
                    overflow: hidden;
                }
            }
        }

        .scroll-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    aui-visualization {
        cursor: default;
    }

    .empty-tab {
        height: 100%;

        .empty-tab-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            h2 {
                margin: 0;
            }

            p {
                font-size: 16px;
                margin: 20px 0;
                max-width: 380px;
            }

            .image-wrapper {
                align-items: center;
                background-color: @gray3;
                border-radius: 50%;
                display: flex;
                height: 200px;
                width: 200px;
                justify-content: center;
                position: relative;
                margin-bottom: 30px;

                i {
                    color: @gray5;
                    font-size: 75px;
                    cursor: auto;
                }
            }
        }
    }
}

@enable-css-reset: false;