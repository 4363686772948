@import (reference) "../../cosmos-manager.less";

cosmos-manager-editor {
    .flex-layout-column;
    overflow: hidden;

    .table {
        tbody {
            tr {
                td {
                    &.exporter-name {
                        padding-left: 0;

                        input {
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

        // The list of rules below defines widths of columns.
        // For clearness, do not extend those selectors with
        // any other properties, but rather apply new rules
        // somewhere else in this file
        &.table-common {
            th:first-child {
                width: 30%;
            }
        }
    }

    .cloud-info {
        padding-left: 30px;
        font-size: 1.5em;
    }
}

// Overridding setting from the app skeleton, as highest dropdown here is not
// present at the bottom of the page. Need to explicitly use long list of
// selectors to make sure that this one is more accurate than the one defined
// in the app skeleton's less file
.app-skeleton cosmos-manager-editor .cosmos-manager-editor ng-form.editor-form .scroll-container fieldset .spacer {
    padding-bottom: 50px;
}

@enable-css-reset: false;