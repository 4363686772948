@import (reference) "../../../../common/styles/aria-web";

resource-picker {
    .project-heading {
        .aui-list-heading {
            background-color: @fluent-gray30;

            &:hover {
                background-color: @fluent-gray40;
            }
        }
    }

    aui-accordion {
        .panel-group {
            .scroll-padding-bottom;
        }

        .aui-accordion {
            background: transparent;

            .panel-group .panel .panel-heading {
                background-color: @fluent-gray40;

                &:hover {
                    background-color: @fluent-gray50;
                }

                .panel-title:hover {
                    background: transparent;
                }

                & .aui-accordion-heading .aui-accordion-heading-text .aui-accordion-heading-flag-number {
                    color: @blue;
                }
            }
        }

        .aui-list-item, .inline-doc-editor {
            padding-left: 30px;
        }
    }

    notebook-list {
        height: 100%;

        .notebook-list {
            height: 100%;
            .flex-layout-column;
        }
    }

    .aui-list {
        width: 100%;
    }
}

@enable-css-reset: false;