@import (reference) "../../../common/styles/aria-web";

.event-viewer {
    .information {
        display: none;
    }

    .spinner {
        margin-bottom: 10px;
    }

    .viz-renderer {
        margin-top: 30px;
        width: 800px;
        height: 400px;

        aui-visualization {
            padding: 8px;
        }
    }

    a {
        .aria-regular;
    }

    .next-button {
        background-color: @blue;
        display: block;
        float: right;
        margin-top: 30px;
    }

    .ee-link {
        float: left;
        margin-top: 37px;
        margin-left: 12px;
    }
}

@enable-css-reset: false;