@import (reference) "../../../common/styles/aria-web";

event-explorer-inspector {
    .scroll-padding-bottom;

    height: 100%;
    width: 100%;
    position: absolute;
    overflow: auto;
}

@enable-css-reset: false;