@import (reference) "../../../../common/styles/aria-web";

.app-skeleton {
    .copy-string {
        color: @gray6;
        display: flex;
        align-items: center;
        cursor: pointer;
        line-height: 1.6;
        padding: 0 8px;
        height: 100%;

        &:hover, &:focus {
            color: @blue;

            &.show-icon {
                i {
                    display: inline;
                }
            }
        }

        > div {
            display: inline-block;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        i {
            .icon-1x;
            color: inherit;
            display: none;
        }
    }
}

@enable-css-reset: false;